const accountRouter = {
    path: "/accounting",
    name: "accounting",
    icon: "fas fa-file-invoice-dollar",
    component: () => import("@/views/layout/childLayout/ChildLayoutCache.vue"),
    meta: {
        name: "accounting",
        text: "會計對帳",
    },
    children: [
        {
            path: "stored_list",
            name: "stored_list",
            component: () => import("@/views/accounting/StoredList.vue"),
            meta: {
                name: "stored_list",
                text: "付款記錄",
                parent: "accounting",
                isMenu: true,
            },
        },
        {
            path: "stored_detail/:paymentID",
            name: "stored_detail",
            component: () => import("@/views/accounting/Id.vue"),
            meta: {
                text: "代付款項記錄・詳情",
                parent: "accounting",
                isMenu: false,
            },
        },
        {
            path: "salary_withdraw_record",
            name: "salary_withdraw_record",
            component: () => import("@/views/accounting/WithdrawRecord.vue"),
            meta: {
                name: "salary_withdraw_record",
                text: "代付款項記錄",
                parent: "accounting",
                isMenu: true,
            },
        },
        {
            path: "salary_withdraw_issuance",
            name: "salary_withdraw_issuance",
            component: () => import("@/views/accounting/WithdrawIssuance/index.vue"),
            meta: {
                name: "salary_withdraw_issuance",
                text: "代付款項核發",
                parent: "accounting",
                isMenu: true,
            },
            children: [
                {
                    path: "submit_application",
                    name: "submit_application",
                    component: () => import("@/views/accounting/WithdrawIssuance/SubmitApplication.vue"),
                    meta: {
                        name: "SubmitApplication",
                        text: "立即提領 提出申請",
                        isMenu: false,
                    },
                },
                {
                    path: "immediately_withdraw",
                    name: "immediately_withdraw",
                    component: () => import("@/views/accounting/WithdrawIssuance/ImmediatelyWithdraw.vue"),
                    meta: {
                        name: "ImmediatelyWithdraw",
                        text: "立即提領 撥款處理",
                        isMenu: false,
                    },
                },
                {
                    path: "salary_withdraw",
                    name: "salary_withdraw",
                    component: () => import("@/views/accounting/WithdrawIssuance/SalaryWithdraw.vue"),
                    meta: {
                        name: "SalaryWithdraw",
                        text: "月結款項 撥款處理",
                        isMenu: false,
                    },
                },
                {
                    path: "error_withdraw",
                    name: "error_withdraw",
                    component: () => import("@/views/accounting/WithdrawIssuance/ErrorWithdraw.vue"),
                    meta: {
                        name: "ErrorWithdraw",
                        text: "異常提領",
                        isMenu: false,
                    },
                },
            ],
        },
        {
            path: "invoice_list",
            name: "invoice_list",
            component: () => import("@/views/accounting/WithdrawIssuance/InvoiceList.vue"),
            meta: {
                name: "invoice_list",
                parent: "accounting",
                text: "發票記錄",
                isMenu: true,
            },
        },
    ],
};

export { accountRouter };
