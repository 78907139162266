const userListRouter = {
    path: "/user_list",
    name: "user_list",
    icon: "fas fa-users",
    component: () => import("@/views/layout/childLayout/ChildLayoutCache.vue"),
    meta: {
        name: "user_list",
        text: "用戶管理",
    },
    children: [
        {
            path: "member_list",
            name: "member_list",
            component: () => import("@/views/user/members/index.vue"),
            meta: {
                text: "會員管理",
                parent: "user_list",
                isMenu: true,
            },
        },
        {
            path: "member_update/:id/:tab?",
            name: "member_update",
            component: () => import("@/views/user/members/updateMember/index.vue"),
            meta: {
                text: "會員個人資料",
                parent: "user_list",
                isMenu: false,
            },
        },
        {
            path: "provider_list",
            name: "provider_list",
            component: () => import("@/views/user/providers/index.vue"),
            meta: {
                text: "服務商管理",
                parent: "user_list",
                isMenu: true,
            },
        },
        {
            path: "provider_add/:id",
            name: "provider_add",
            component: () => import("@/views/user/providers/addProvider/index.vue"),
            meta: {
                text: "新增服務商",
                parent: "user_list",
                isMenu: false,
            },
        },
        {
            path: "provider_update/:id/:tab?",
            name: "provider_update",
            component: () => import("@/views/user/providers/updateProvider/index.vue"),
            meta: {
                text: "服務商個人資料",
                parent: "user_list",
                isMenu: false,
            },
        },
        {
            path: "/:userID/:pre/:tab/order_detail/:orderID",
            name: "order_detail",
            component: () => import("@/views/user/orders/orderDetail.vue"),
            meta: {
                text: "訂單記錄",
                parent: "order_manage",
                isMenu: false,
            },
        },
        {
            path: "verification_list",
            name: "verification_list",
            component: () => import("@/views/user/verifications/index.vue"),
            meta: {
                name: "verification_list",
                text: "實名驗證",
                parent: "user_list",
                isMenu: true,
            },
            children: [
                {
                    path: "wait_for_check",
                    name: "wait_for_check",
                    component: () => import("@/views/user/verifications/WaitForCheck.vue"),
                    meta: {
                        name: "wait_for_check",
                        text: "等待確認",
                        isMenu: false,
                    },
                },
                {
                    path: "not_allow",
                    name: "not_allow",
                    component: () => import("@/views/user/verifications/NotAllow.vue"),
                    meta: {
                        name: "not_allow",
                        text: "驗證失敗紀錄",
                        isMenu: false,
                    },
                },
                {
                    path: "checked",
                    name: "checked",
                    component: () => import("@/views/user/verifications/Checked.vue"),
                    meta: {
                        name: "checked",
                        text: "通過審核紀錄",
                        isMenu: false,
                    },
                },
            ],
        },
        {
            path: "verification_detail/:id",
            name: "verification_detail",
            component: () => import("@/views/user/verifications/Id.vue"),
            meta: {
                name: "verification_detail",
                text: "實名驗證",
                parent: "user_list",
                isMenu: false,
            },
        },
        {
            path: "introducer_list",
            name: "introducer_list",
            component: () => import("@/views/user/introducer/index.vue"),
            meta: {
                name: "introducer_list",
                text: "介紹人列表",
                parent: "user_list",
                isMenu: true,
            },
        },
        {
            path: "add_introducer",
            name: "add_introducer",
            component: () => import("@/views/user/introducer/addIntroducer/index.vue"),
            meta: {
                name: "add_introducer",
                text: "新增介紹人",
                parent: "user_list",
            },
        },
        {
            path: "edit_introducer/:id",
            name: "edit_introducer",
            component: () => import("@/views/user/introducer/editIntroducer/index.vue"),
            meta: {
                name: "edit_introducer",
                text: "編輯介紹人",
                parent: "user_list",
            },
            children: [
                {
                    path: "profile",
                    name: "edit_introducer_profile",
                    component: () => import("@/views/user/introducer/editIntroducer/components/Profile.vue"),
                    meta: {
                        name: "edit_introducer_profile",
                        text: "個人資料",
                        isMenu: false,
                    },
                },
                {
                    path: "account",
                    name: "edit_introducer_account",
                    component: () => import("@/views/user/introducer/editIntroducer/components/Account.vue"),
                    meta: {
                        name: "edit_introducer_account",
                        text: "撥款帳戶",
                        isMenu: false,
                    },
                },
                {
                    path: "providerList",
                    name: "edit_introducer_providerList",
                    component: () => import("@/views/user/introducer/editIntroducer/components/ProviderList.vue"),
                    meta: {
                        name: "edit_introducer_providerList",
                        text: "服務商名單",
                        isMenu: false,
                    },
                },
                {
                    path: "paymentInfo",
                    name: "edit_introducer_paymentInfo",
                    component: () => import("@/views/user/introducer/editIntroducer/components/PaymentInfo.vue"),
                    meta: {
                        name: "edit_introducer_paymentInfo",
                        text: "款項資訊",
                        isMenu: false,
                    },
                },
            ],
        },
        {
            path: "payment_detail_list/:paymentID",
            name: "payment_detail_list",
            component: () => import("@/views/user/introducer/editIntroducer/components/PaymentDetailList.vue"),
            meta: {
                name: "payment_detail_list",
                text: "款項資訊明細",
                parent: "user_list",
            },
        },
    ],
};

export { userListRouter };
