import Vue from "vue";
// 使用國旗icon
import CountryFlag from "vue-country-flag";
Vue.component("country-flag", CountryFlag);

// 日期選擇套件
import LitepieDatepicker from "vue2-litepie-datepicker";
// 日期選擇有用到 vue3 寫法 因此需要導入這隻 套件
import VueCompositionAPI from "@vue/composition-api";
Vue.use(LitepieDatepicker);
Vue.use(VueCompositionAPI);
// 日期選擇套件搭配得日期格式套件
import dayjs from "dayjs";
// 日期格式套件語系
import "dayjs/locale/zh-tw";
// 設定語系為繁體中文
dayjs.locale("zh-tw");

// 彈窗組建
import VueSilentbox from "vue-silentbox";
Vue.use(VueSilentbox);

// 確認彈窗
import { popConfirm, popConfirmType } from "../components/popup/pop";
Vue.prototype.$pop = {
    popConfirm,
    popConfirmType,
};
