import axios from "@/config/httpConfig";
import defaultAxios from "axios";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
/**
 * 登入api
 * @param { type Object(物件)
 * name: 帳號 type String(字串)
 * password: 密碼 type String(字串)
 *  } data
 */
export const LoginApi = (data) => {
    return defaultAxios.post(`${apiConfig}/backyard/login`, data);
};
// 更新 token api
export const RefreshTokenApi = () => {
    return axios.post(`${apiConfig}/backyard/refresh`);
};
// 取得登入使用者資料
export const LoginUserInfoApi = () => {
    return axios.get(`${apiConfig}/backyard/user-profile`);
};
// 取得使用者餘額
export const GetUserBalance = (id) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/balance`);
};
// 登出 api
export const LogoutUserApi = () => {
    return axios.post(`${apiConfig}/backyard/logout`);
};
/**
 * 手機號碼搜尋使用者
 * @param { type Number or String(數字或字串) } phone 使用者手機號碼
 * @example { phone: 886900123123 }
 * @returns {
    "id": 485,
    "banana_id": "uqv7qw3075",
    "name": "咸思",
    "real_name": "仲冠",
    "phone": "886998979725",
    "email": "wan.yang@example.net",
    "birthday": "1980-06-12",
    "age": 31,
    "district": "TW-KIN",
    "gender": "female",
    "height": 156,
    "weight": 63,
    "locale": "zh-TW",
    "description": "候，間或瞪著眼，總還是辮子盤在頭上搔癢，便愈喜歡的玩意兒，坐下，靠門立住腳。",
    "marketing_notification": 1,
    "wallet": null,
    "media": {
        "cover": {
            "file": "uqv7qw3075.jpg",
            "type": "image"
        }
    },
    "role": 1,
    "government_id": "P232289370",
    "address": "116-77 花蓮縣玉里鎮厚北路二段723巷738弄123號49樓",
    "service_area": "TW-ILA",
    "rating_score": 3.4,
    "banking": null,
    "consent": [],
    "status": 0,
    "last_seen_at": "2021-07-02T05:45:18.000000Z",
    "avatar": "https://cdn-dev.citybanana.com/user/avatar/default.jpg",
    "cover": "https://cdn-dev.citybanana.com/user/cover/uqv7qw3075.jpg?1625242202",
    "isMyFavorite": false
}
 */
export const UserSearchByPhone = (phone) => {
    return axios.get(`${apiConfig}/backyard/search-profile`, {
        params: { phone },
    });
};

/**
 * 更新使用者資料 api
 * @param { type String(字串) } userId 使用者 id
 * @param { type Object(物件) } data 更新資料
 * @example {
    "name": "[unicode size between 1 and 200 chars]",
    "real_name": "[unicode size between 2 and 200 chars]",
    "phone": "[digits between 10 and 20]",
    "email": "[unicode]",
    "password": "[unicode 6 chars min]",
    "birthday": "[date]",
    "age": "[integer between 18 and 99]",
    "district": "[ISO 3166-2]", 
    "gender": "['male' or 'female' or 'unknown']",
    "height": "[numeric]",
    "weight": "[numeric]",
    "locale": "[unicode 5 chars max]",
    "description": "[unicode]",
    "marketing_notification": "[boolean]",
    "wallet": "[json]",
    "government_id": "[unicode size 10 chars]",
    "address": "[unicode]",
    "service_area": "[ISO 3166-2]",
    "banking": "[json]",
}
 */
export const UpdateProfileApi = (userId, data) => {
    return axios.patch(`${apiConfig}/backyard/users/${userId}`, data);
};

/**
 * 新增大頭照
 * @param { type File or Blob (檔案格式 或 Blob) } file 封面照格式
 * @param { type String(字串) } userId 使用者 id
 * @example { image: file }
 * @returns {   
    "message": "Cover successfully uploaded",
    "avatar": "https://cdn-dev.citybanana.com/user/avatar/uqvb6bp936.jpg?1624857648"
}
 */
export const UploadAvatarApi = (userId, file) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/avatar`, file);
};

/**
 * 新增封面照
 * @param { type File or Blob (檔案格式 或 Blob) } file 封面照格式
 * @param { type String(字串) } userId 使用者 id
 * @example { image: file }
 * @returns {   
    "message": "Cover successfully uploaded",
    "cover": "https://cdn-dev.citybanana.com/user/cover/uqv7qw3075.jpg?1625323899"
}
 */
export const UploadCoverApi = (userId, file) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/cover`, file);
};

/**
 * 刪除封面照
 * @param { type String(字串) } userId 使用者 id
 */
export const DeleteCoverApi = (userId) => {
    return axios.delete(`${apiConfig}/backyard/users/${userId}/cover`);
};

/**
 * 新增其他照片
 * @param { type String(字串) } userId 使用者 id
 * @param { type File or Blob (檔案格式 或 Blob) } file 其他照片
 * @example { image: file }
 */
export const UploadOtherPhotoApi = (userId, file) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/photos`, file);
};

/**
 * 刪除其他照片
 * @param { type String(字串) } userId 使用者 id
 * @param { type Strin(字串) } photoId 圖片id
 * @returns {
    "message": "Photo successfully deleted"
}
 */
export const DeleteOtherPhotoApi = (userId, photoId) => {
    return axios.delete(`${apiConfig}/backyard/users/${userId}/photos/${photoId}`);
};

/**
 * 新增或更新所有分類資料
 * @param { type String(字串) } userId 使用者 id
 * @param { tyep Object(物件) } data 分類資料
 * @example {
    "categories": {
        "[category id]": {
            "price": "[integer 1000 min]",
            "min_dating_unit": "[integer between 1 and 24]", 
            "status": "[integer 0 or 1 or 2]"
        }
    }
}
 * @returns
 */
export const UpdateOrCreateAllCategories = (userId, data) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/categories`, data);
};

/**
 * 更新供給者服務時間
 * @param { type String(字串) } userId 使用者 id
 * @param {type Array(陣列)} hours 供應商 服務時間
 * @example {"hours": [9, 10, 11, 12, 15, 16, 17]}
 * @returns 
 * {
    "message": "Business hours successfully updated",
    "hours": [9,10,11,12,15,16,17]
}
 */
export const UpdateOrCreateBusinessHourApi = (userId, hours) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/business-hours`, {
        hours,
    });
};
/**
 * 取得供給者服務時間
 * @param { type String(字串) } userId 使用者 id
 * @returns 
 * {
    "hours": [7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
}
 */
export const GetBusinessHourApi = (userId) => {
    return axios.get(`${apiConfig}/backyard/users/${userId}/business-hours`);
};

/**
 * 取得單一使用者資料
 * @param { type String(字串) } userId 使用者 id
 * @returns
 */
export const GetUserDataApi = (userId) => {
    return axios.get(`${apiConfig}/backyard/users/${userId}`);
};

/**
 * 客服註記發送
 * @param { type String(字串) } userId 使用者 id
 * @param { type String(字串) } note 註記內容
 * @returns
 */
export const CreateNotesApi = (userId, note) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/user-support-logs`, {
        note,
    });
};
/**
 * 取得客服註記列表
 * @param { type String(字串) } userId 使用者 id
 * @param { type String or Number(字串或數字) } limit 一頁呈現幾筆資料
 * @returns
 */
export const GetNotesListApi = (userId, limit) => {
    return axios.get(`${apiConfig}/backyard/users/${userId}/user-support-logs`, {
        params: { limit },
    });
};

/**
 * 上傳存摺
 * @param { type String(字串) } userId 使用者 id
 * @param { type Bolb or File(Blob或檔案格式) } image 存摺照片
 * @returns
 */
export const UplaodUserBankAccountPhotoApi = (userId, image) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/passbook`, image);
};
/**
 * 取得存摺照片
 * @param { type String(字串) } userId 使用者 id
 * @returns
 */
export const GetUserBankAccountPhotoApi = (userId) => {
    return `${apiConfig}/backyard/users/${userId}/passbook`;
};
/**
 * 上傳影片 api
 * @param { type String(字串) } userId 使用者 id
 * @param { type File(影片檔案) } video 影片檔案
 * @returns
 */
export const UploadVideoApi = (userId, video, onUploadProgress) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/videos`, video, {
        onUploadProgress,
    });
};
/**
 * 刪除影片 api
 * @param { type String(字串) } userId 使用者 id
 * @param { type String(字串) } videoId 影片 id
 * @returns
 */
export const DeleteVideoApi = (userId, videoId) => {
    return axios.delete(`${apiConfig}/backyard/users/${userId}/videos/${videoId}`);
};
/**
 * 上傳影片 api
 * @param { type String(字串) } userId 使用者 id
 * @param { type String(字串) } videoId 影片 id
 * @param { type Blob or File(Blob 或 檔案格式) } image 影片封面照
 * @returns
 */
export const UploadVideoCoverApi = (userId, videoId, image) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/videos/${videoId}/cover`, image);
};

/**
 * 解除第三方綁定 api
 * @param { type String(字串) } userId 使用者 id
 * @param { type String(字串) } platform 解除綁定第三方名稱
 * @returns
 */
export const UnLinkSocialMediaApi = (userId, platform) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/unlink-oauth-user`, { platform });
};

/**
 * 取得簡訊通知設定值
 * @param { type String(字串) } userId 使用者 id
 * @returns
 */
export const GetSettingsApi = (userId) => {
    return axios.get(`${apiConfig}/backyard/users/${userId}/settings`);
};

/**
 * 更新是否收到簡訊通知設定值
 * @param { type String(字串) } userId 使用者 id
 * @param { type Boolan(布林值) } receiveDatingAcceptedSMS 開關是否收到簡訊通知設定值
 * @example { receiveDatingAcceptedSMS: true (願意收到通知) , receiveDatingCreatedSMS : 1 (服務商接收簡訊) }
 * @returns
 */
export const UpdateProfileSetting = (userId, data) => {
    return axios.patch(`${apiConfig}/backyard/users/${userId}/settings`, data);
};

export default {
    LoginApi,
    RefreshTokenApi,
    LoginUserInfoApi,
    LogoutUserApi,
    UserSearchByPhone,
    GetUserBalance,
    UpdateProfileApi,
    UploadAvatarApi,
    UploadCoverApi,
    DeleteCoverApi,
    UploadOtherPhotoApi,
    DeleteOtherPhotoApi,
    UpdateOrCreateAllCategories,
    UpdateOrCreateBusinessHourApi,
    GetBusinessHourApi,
    GetUserDataApi,
    CreateNotesApi,
    GetNotesListApi,
    UplaodUserBankAccountPhotoApi,
    GetUserBankAccountPhotoApi,
    UploadVideoApi,
    DeleteVideoApi,
    UploadVideoCoverApi,
    UnLinkSocialMediaApi,
    GetSettingsApi,
    UpdateProfileSetting,
};
