import { getStorage, removeStorage } from "@/service/localStorage";
// 取得登入者資料 api
import { LoginUserInfoApi } from "@/api/cms/userApi";
// 取得經紀人登入資料
import { LoginBrokerInfoApi } from "@/api/broker/loginApi";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";
import { Message } from "element-ui";

export default {
    namespaced: true,
    // data
    state: {
        isAuth: false,
        user: {}, // 使用者資料
    },
    // computed
    getters: {},
    // methods
    mutations: {
        // 設定為登入
        setIsAuth(state, val) {
            state.isAuth = val;
        },
        // 設定使用者資料
        setUser(state, val) {
            state.user = val;
        },
    },
    // mounted ajax的方法都寫在這
    actions: {
        // 取得使用者資料
        async getLoginUserInfo({ commit }) {
            if (getStorage("token")) {
                try {
                    const { data } = await LoginUserInfoApi();
                    commit("setUser", data);
                    // 設定是否為最高管理者
                    const isAdmin = data.roles.some((i) => {
                        return i.id === 1;
                    });
                    commit("permissionStore/setRoles", { roles: [...data.roles] }, { root: true });
                    if (isAdmin) {
                        commit("permissionStore/setPermissions", { permissions: [] }, { root: true });
                        commit("permissionStore/setIsAdmin", true, { root: true });
                    } else {
                        commit("permissionStore/setPermissions", { permissions: [...data.permissions] }, { root: true });
                    }
                    commit("setIsAuth", true);
                    return data.data;
                } catch (err) {
                    Message({
                        type: "error",
                        message: "取得使用者資料失敗",
                    });
                }
            }
        },
        // 經紀人後台取得使用者資料
        async getLoginBrokerInfo({ commit }) {
            if (getStorage("token")) {
                try {
                    const { data } = await LoginBrokerInfoApi();
                    commit("setUser", data);
                    commit("setIsAuth", true);
                    return data.data;
                } catch (err) {
                    Message({
                        type: "error",
                        message: "取得使用者資料失敗",
                    });
                }
            }
        },
        // 登出使用者清除localStorage
        logOutUser({ commit, dispatch }) {
            removeStorage("token");
            removeStorage("lang");
            removeStorage("loginTime");
            removeStorage("refreshTime");
            removeStorage("tokenTime");
            removeStorage("agentName");
            removeStorage("depth");
            removeStorage("defaultDepth");
            removeStorage("agentUserId");
            removeStorage("agentAddId");
            removeStorage("parent");
            removeStorage("isAuth");
            removeStorage("noticeLastId");
            removeStorage("tokenRefreshTime");
            removeStorage("firebaseTokenRefreshTime");
            commit("setIsAuth", false);
            commit("setUser", {});
            commit("permissionStore/setIsAdmin", false, { root: true });
            dispatch("chatStore/logoutFirebaseAuth", null, { root: true });
            // 移除firebase 聊天對象監聽
            firebaseConnectRef("chat_rooms").off();
            // 移除 firebase 聊天室監聽
            firebaseConnectRef("chats").off();
        },
    },
};
