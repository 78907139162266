const customerComplaintRouter = {
    path: "customer_complaint",
    name: "customer-complaint",
    icon: "fas fa-compress",
    component: () => import("@/views/layout/childLayout/ChildLayoutCache.vue"),
    meta: {
        name: "customer-complaint",
        text: "客訴處理",
        custom_route_name: "customer-complaint-list",
    },
    children: [
        {
            path: "",
            name: "customer-complaint-list",
            component: () => import("@/views/customerComplaint/index.vue"),
            meta: {
                name: "customer-complaint-list",
                text: "客訴單列表",
                parent: "customer-complaint",
                isMenu: false,
            },
        },
        {
            path: "customer/:customerID",
            name: "customer-complaint-detail",
            component: () => import("@/views/customerComplaint/Id.vue"),
            meta: {
                name: "customer-complaint-detail",
                parent: "customer-complaint",
                text: "客訴詳情",
                isMenu: false,
            },
        },
    ],
};

export { customerComplaintRouter };
