import Vue from "vue";
import { isEmpty } from "../service/anyService";

// vue 全域 mixin
Vue.mixin({
    data() {
        return {
            isMobile: false,
            nowWindowSize: {
                width: 0,
                height: 0,
            },
        };
    },
    methods: {
        $isEmpty(val) {
            return isEmpty(val);
        },
        /**
         * 判斷字元過長就出現...
         * @param { type String(字串) 要過濾的文字 } val
         * @param { type Number(數字) 要過濾的文字長度 } num
         */
        $subString(val, num) {
            // 判斷非字串格式時 回傳空字串
            if (typeof val !== "string") {
                return "";
            }
            if (val.length > num) {
                return val.substring(0, num) + "...";
            }
            return val;
        },
        /**
         * 判斷語系檔 是否存在 不存在則回傳key
         * @param { type Object (物件) 該值為語系檔的值} value
         * @param { type String (字串) 該值為檔案名稱 } langFile
         * @param { type String (字串) 該值為要傳入對應語系檔得key } langKey
         */
        $checkLang(value, langFile, langKey) {
            if (value !== null) {
                if (value[langFile] !== null && value[langFile] !== undefined) {
                    if (value[langFile][langKey] === undefined) {
                        return langKey;
                    } else {
                        return value[langFile][langKey].name;
                    }
                } else {
                    return "連語系檔都還沒有";
                }
            }
        },
        // 監控目前視窗大小
        handleResize() {
            this.nowWindowSize.width = window.innerWidth;
            this.nowWindowSize.height = window.innerHeight;
            if (this.nowWindowSize.width > 600) {
                this.isMobile = false;
            } else {
                this.isMobile = true;
            }
        },
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
});
