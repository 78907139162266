const homepageSettingRouter = {
    path: "/homepage_setting",
    name: "homepage_setting",
    icon: "fas fa-home",
    component: () => import("@/views/layout/childLayout/ChildLayoutSetting.vue"),
    meta: {
        name: "homepage_setting",
        text: "首頁設定",
        custom_route_name: "top_banners",
    },
    children: [
        {
            path: "top_banners",
            name: "top_banners",
            component: () => import("@/views/homepageSetting/topBanner/index.vue"),
            meta: {
                name: "TopBanners",
                text: "置頂 Banner",
                parent: "homepage_setting",
                isMenu: false,
            },
        },
        {
            path: "top_banners/add",
            name: "add_top_banner",
            component: () => import("@/views/homepageSetting/topBanner/AddBanner.vue"),
            meta: {
                name: "AddTopBanner",
                text: "新增置頂 Banner",
                isMenu: false,
            },
        },
        {
            path: "top_banners/edit/:id",
            name: "edit_top_banner",
            component: () => import("@/views/homepageSetting/topBanner/EditBanner.vue"),
            meta: {
                name: "EditTopBanner",
                text: "編輯置頂 Banner",
                isMenu: false,
            },
        },
        {
            path: "area_banners",
            name: "area_banners",
            component: () => import("@/views/homepageSetting/areaBanner/index.vue"),
            meta: {
                name: "AreaBanners",
                text: "區塊 Banner",
                parent: "homepage_setting",
                isMenu: false,
            },
        },
        {
            path: "area_banners/add",
            name: "add_area_banner",
            component: () => import("@/views/homepageSetting/areaBanner/AddBanner.vue"),
            meta: {
                name: "AddAreaBanner",
                text: "新增區塊 Banner",
                isMenu: false,
            },
        },
        {
            path: "area_banners/edit/:id",
            name: "edit_area_banner",
            component: () => import("@/views/homepageSetting/areaBanner/EditBanner.vue"),
            meta: {
                name: "EditAreaBanner",
                text: "編輯區塊 Banner",
                isMenu: false,
            },
        },
        {
            path: "promote_img",
            name: "promote_img",
            component: () => import("@/views/homepageSetting/promoteIMG/index.vue"),
            meta: {
                name: "PromoteIMG",
                text: "推廣大圖",
                parent: "homepage_setting",
                isMenu: false,
            },
        },
        {
            path: "promote_img/add/:phone",
            name: "add_promote",
            component: () => import("@/views/homepageSetting/promoteIMG/AddPromo.vue"),
            meta: {
                name: "AddPromoteIMG",
                text: "新增推廣大圖",
                isMenu: false,
            },
        },
        {
            path: "promote_img/edit/:id",
            name: "edit_promote",
            component: () => import("@/views/homepageSetting/promoteIMG/EditPromo.vue"),
            meta: {
                name: "EditPromoteIMG",
                text: "編輯推廣大圖",
                isMenu: false,
            },
        },
        {
            path: "newcomer_list",
            name: "newcomer_list",
            component: () => import("@/views/homepageSetting/newComer/index.vue"),
            meta: {
                name: "NewComerList",
                text: "猜你喜歡",
                parent: "homepage_setting",
                isMenu: false,
            },
        },
        {
            path: "newcomer/add/:phone",
            name: "add_comer",
            component: () => import("@/views/homepageSetting/newComer/AddComer.vue"),
            meta: {
                name: "add_comer",
                text: "新增猜你喜歡",
                isMenu: false,
            },
        },
        {
            path: "newcomer/edit/:id",
            name: "edit_comer",
            component: () => import("@/views/homepageSetting/newComer/EditComer.vue"),
            meta: {
                name: "edit_comer",
                text: "編輯猜你喜歡",
                isMenu: false,
            },
        },
        {
            path: "footer_setting",
            name: "footer_setting",
            component: () => import("@/views/homepageSetting/footerSetting/index.vue"),
            meta: {
                name: "FooterSetting",
                text: "Footer 設定",
                parent: "homepage_setting",
                isMenu: false,
            },
        },
        {
            path: "lastest_news",
            name: "lastest_news",
            component: () => import("@/views/homepageSetting/lastestNews/index.vue"),
            meta: {
                name: "lastest_news",
                text: "最新消息",
                parent: "homepage_setting",
                isMenu: false,
            },
        },
        {
            path: "lastest_news/add",
            name: "add_new",
            component: () => import("@/views/homepageSetting/lastestNews/AddNew.vue"),
            meta: {
                name: "add_new",
                text: "新增最新消息",
                isMenu: false,
            },
        },
        {
            path: "lastest_news/edit/:newid",
            name: "edit_new",
            component: () => import("@/views/homepageSetting/lastestNews/EditNew.vue"),
            meta: {
                name: "edit_new",
                text: "編輯最新消息",
                isMenu: false,
            },
        },
        {
            path: "fighting_rules",
            name: "fighting_rules",
            component: () => import("@/views/homepageSetting/fightingRules/index.vue"),
            meta: {
                name: "fighting_rules",
                text: "教戰守則",
                parent: "homepage_setting",
                isMenu: false,
            },
        },
        {
            path: "fighting_rules/add",
            name: "add_rule",
            component: () => import("@/views/homepageSetting/fightingRules/AddRule.vue"),
            meta: {
                name: "add_rule",
                text: "新增教戰守則",
                isMenu: false,
            },
        },
        {
            path: "fighting_rules/edit/:ruleid",
            name: "edit_rule",
            component: () => import("@/views/homepageSetting/fightingRules/EditRule.vue"),
            meta: {
                name: "edit_rule",
                text: "編輯教戰守則",
                isMenu: false,
            },
        },
    ],
};

export { homepageSettingRouter };
