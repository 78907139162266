export default {
    path: "/campaigns",
    name: "campaigns",
    icon: "fas fa-volume-up",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "campaigns_control",
        text: "活動內容",
        custom_route_name: "campaigns-list",
    },
    children: [
        {
            path: "",
            name: "campaigns-list",
            icon: "fas fa-volume-up",
            component: () => import("@/views/campaign/index.vue"),
            meta: {
                name: "campaigns-list",
                parent: "campaigns_control",
                text: "活動內容",
                icon: "fas fa-volume-up",
                isMenu: true,
            },
        },
        {
            path: "/campaign-create",
            name: "campaign-create",
            icon: "fas fa-volume-up",
            component: () => import("@/views/campaign/Form.vue"),
            meta: {
                name: "campaign-create",
                parent: "campaigns_control",
                text: "新增活動",
                icon: "fas fa-volume-up",
                isMenu: false,
            },
        },
        {
            path: "/campaign-update/:campaignID",
            name: "campaign-update",
            icon: "fas fa-volume-up",
            component: () => import("@/views/campaign/Form.vue"),
            meta: {
                name: "campaign-update",
                parent: "campaigns_control",
                text: "編輯活動",
                icon: "fas fa-volume-up",
                isMenu: false,
            },
        },
    ],
};
