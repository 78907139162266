import { homepageSettingRouter } from "./routers/homePageSetRouter";
import { accountRouter } from "./routers/accountRouter";
import { systemControlRouter } from "./routers/systemControlRouter";
import { chatControlRouter } from "./routers/chatControlRouter";
import { userListRouter } from "./routers/userListRouter";
import { customerComplaintRouter } from "./routers/customerComplaint";
import { exportExcelRouter } from "./routers/exportExcelRouter";
import permissionRouter from "./routers/permissionRouter";
import campaignRouter from "./routers/campaignRouter";

const dynamicRoutes = [
    exportExcelRouter,
    systemControlRouter,
    chatControlRouter,
    userListRouter,
    customerComplaintRouter,
    accountRouter,
    homepageSettingRouter,
    permissionRouter,
    campaignRouter,
];

export default dynamicRoutes;
