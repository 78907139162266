import Vue from "vue";
import moment from "moment";
import "moment/locale/zh-tw";
// 導入 中文化 時區字串
moment.locale("zh-tw");
// 客製化 時區字串
moment.updateLocale("zh-tw", {
    relativeTime: {
        future: "in %s",
        past: "%s 之前",
        s: function (number, withoutSuffix) {
            return withoutSuffix ? "now" : "幾秒鐘前";
        },
        m: "1分鐘",
        mm: "%d分鐘",
        h: "1小時",
        hh: "%d小時",
        d: "1日",
        dd: "%d日",
        M: "1m月份",
        MM: "%d月份",
        y: "1年",
        yy: "%d年",
    },
});

Object.defineProperty(Vue.prototype, "$moment", { value: moment });
