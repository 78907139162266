import store from "@/store";

export default {
    // eslint-disable-next-line no-unused-vars
    inserted(el, binding, vnode) {
        const { value } = binding;
        const roles = store.getters["permissionStore/currentPageCRUD"];
        let hasPermission = true;
        if (value && value instanceof Array && value.length > 0) {
            if (value.includes("update")) {
                if (!roles.Update) {
                    hasPermission = false;
                }
            }

            if (value.includes("create")) {
                if (!roles.Create) {
                    hasPermission = false;
                }
            }

            if (value.includes("delete")) {
                if (!roles.Delete) {
                    hasPermission = false;
                }
            }

            if (value.includes("read")) {
                if (!roles.Read) {
                    hasPermission = false;
                }
            }

            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el);
            }
        } else {
            throw new Error(`need roles! Like v-permission="['admin','editor']"`);
        }
    },
};
