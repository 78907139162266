<template>
    <div id="app" class="w-full" :class="showDark ? 'dark' : ''">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["showDark"]),
    },
};
</script>
