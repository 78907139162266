<template>
    <Dialog :showDialog="param.show" :customClass="'position'">
        <div class="pop-width md:p-10 px-5 py-3 bg-white rounded">
            <div class="close" @click="cancel">
                <i class="fas fa-times"></i>
            </div>
            <div class="content">
                <div class="title">
                    {{ param.title }}
                </div>
                <div class="des">{{ param.message }}</div>
            </div>
            <div class="input-confirm">
                <el-input v-model="input" placeholder="CONFIRM" />
            </div>
            <div class="flex justify-center mt-3 border-gray-100 pt-2">
                <el-button :disabled="disable" class="orange-btn-800 md:text-sm text-xs w-[73px] h-[30px]" @click="confirm"> 確認 </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
export default {
    name: "PopupTypeConfirm",
    components: {
        Dialog,
    },
    props: {
        param: {
            type: Object,
            default() {
                return {
                    show: false,
                    title: "",
                    message: "",
                };
            },
        },
    },
    data() {
        return {
            input: "",
            disable: true,
        };
    },
    methods: {
        cancel() {
            const param = this.param;
            param.show = false;
            param.cancel && param.cancel();
        },

        confirm() {
            if (this.input === "CONFIRM") {
                const param = this.param;
                param.show = false;
                param.confirm && param.confirm();
            }
            return;
        },
    },
    watch: {
        input(val) {
            if (val === "CONFIRM") {
                this.disable = false;
            } else {
                this.disable = true;
            }
        },
    },
};
</script>

<style lang="scss">
.pop-width {
    max-width: 355px;
    margin: 0 162px 0 0;
    position: relative;
    .close {
        top: 15px;
        right: 20px;
        position: absolute;
        font-size: 20px;
        &:hover {
            color: #ccc;
            cursor: pointer;
        }
    }
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
            font-size: 16px;
        }
        .des {
            margin-top: 10px;
            font-size: 12px;
            color: #707070;
        }
    }
    .input-confirm {
        margin-top: 10px;
    }
}
@media (min-width: 768px) {
    .position {
        top: calc(25% + 85px);
        left: calc(50% - 125px);
    }
}
</style>
