// element ui alert 提醒功能
import { Message } from "element-ui";
// 導入 所有需要權限的router
import dynamicRoutes from "@/router/dynamicRouter";
/**
 * permissionRouter = 過濾所有router方法，指返回該用戶擁有的權限路由
 * setDefaultRoute = 設定登入就可看到的路由頁面
 */
import { permissionRouter, setDefaultRoute } from "@/service/routerService";
/**
 * router = 初始化路由 (vue router) 方法
 * defaultRouter = 登入即可觀看的路由
 */
import router from "@/router/index";
import { defaultRouter } from "../../router/utils/cmsEntry";
export default {
    namespaced: true,
    // data
    state: {
        isAdmin: false,
        // 初始權限的路由
        permissionList: null,
        permissions: [],
        // 當前登入角色資料
        roles: [],
        // 左側選單
        sideBarMenu: [],
        // 樹狀結構權限
        permissionTree: [],
        // 設定當前頁面CRUD
        currentPageCRUD: {},
    },
    // coumputed
    getters: {
        currentPageCRUD(state) {
            return state.currentPageCRUD;
        },
    },
    // methods
    mutations: {
        // 設定角色
        setRoles(state, routes) {
            const { roles } = routes;
            state.roles = roles;
            // 設定最高權限
            const idx = roles.findIndex((i) => i.id === 1);
            if (idx !== -1) {
                state.isAdmin = true;
            } else {
                state.isAdmin = false;
            }
        },
        // 設定初始權限路由值
        setPermissions(state, routes) {
            const { permissions } = routes;
            state.permissions = permissions;
        },
        // 設定初始權限路由值
        setPermissionList(state, routes) {
            state.permissionList = routes;
        },
        // 設定左側選單
        setSideBarMenu(state, menu) {
            state.sideBarMenu = menu;
        },
        // 設定樹狀結構的權限
        setPermissionTree(state, tree) {
            state.permissionTree = tree;
        },
        // 設定當前頁面 CRUD
        setCurrentPageCRUD(state, tree) {
            state.currentPageCRUD = tree;
        },
        // 設定為 admin
        setIsAdmin(state, val) {
            state.isAdmin = val;
        },
    },
    // mounted
    actions: {
        //es7 同步 ajax 寫法
        async getPermissionList({ commit, state }) {
            try {
                // 獲取登入的使用者過濾完後可訪問的權限路由
                const permissionRoutes = permissionRouter(dynamicRoutes, state.permissions, state.isAdmin);
                // 判斷登入後即可瀏覽的路由頁面 該頁面的path === ''
                const findDefaultRouter = defaultRouter.find((value) => value.path === "");
                // 獲取登入後即可瀏覽的路由底下的children頁面
                const setPermissionRouter = findDefaultRouter.children;
                // 將登入後即可瀏覽的權限與需要權限的路由做整合
                setPermissionRouter.push(...permissionRoutes);
                const result = setPermissionRouter.filter(function (element, index, arr) {
                    return arr.indexOf(element) === index;
                });
                // 設定 SideBarMenu 值;
                commit("setSideBarMenu", result);
                // 設定 樹狀結構權限 值;
                // commit("setPermissionTree", permissionList.data);
                // 初始化 登入後 即可訪問路由的權限
                setDefaultRoute(setPermissionRouter);
                // 初始路由
                const initialRoutes = router.options.routes;
                // 動態添加路由
                router.addRoutes(defaultRouter);
                // 初始的路由表
                commit("setPermissionList", [...initialRoutes, ...defaultRouter]);
            } catch (err) {
                Message({
                    type: "error",
                    message: "取得權限資料失敗",
                });
            }
        },
    },
};
