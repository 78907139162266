import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 取得 活動列表
 * @param { type Object(物件) } params 過濾參數
 * @example {
 * page: (type Number(數字)) 分頁頁碼
 * limie: 20 (type Number(數字)) 單頁呈現幾筆資料,
 * sort_by: (type String(字串)) 排序依據key,
 * name: (type String(字串)) 活動名稱
 * code: (type String(字串)) 活動代碼
 * }
 */
export const GetCampaignsListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/campaigns`, { params });
};
/**
 * 取得 單一活動資料
 * @param { type Object(物件) } params 過濾參數
 * @returns {
    "id": 1,
    "code": "202204NREG",
    "name": "你值得擁有，城市註冊禮",
    "started_at": "2022-03-30T01:00:00.000000Z",
    "ended_at": "2022-03-30T10:00:00.000000Z",
    "details": [],
    "rewards": [
        {
            "type": "voucher",
            "amount": 1000,
            "expired_at": "2022-06-30 23:59:59"
        }
    ],
    "status": 0
}
 */
export const GetCampaignDataApi = (campaignID) => {
    return axios.get(`${apiConfig}/backyard/campaigns/${campaignID}`);
};
/**
 * 新增 活動
 * @param { type Object(物件) } form 活動資料
 * @example {
 * code: (type String(字串)) 活動代碼 required
 * name: (type String(字串)) 活動名稱 required
 * started_at (type Date(日期)) 活動開始時間
 * ended_at (type Date(日期)) 結束時間
 * details (type String(字串) 活動詳情
 * rewards (type Array(陣列)) 活動獎勵內容 required
 * rewards.*.type (type String(字串)) 獎勵類別
 * rewards.*.amount (type Number(數字)) 獎勵額度
 * rewards.*.expired_at (type Date(日期)) 使用期限
 * status (type Number(數字)) 啟用狀態 required
 * }
 */
export const CampaignCreateApi = (form) => {
    return axios.post(`${apiConfig}/backyard/campaigns`, form);
};
/**
 * 更新 活動
 * @param { type Object(物件) } form 活動資料
 * @example {
 * code: (type String(字串)) 活動代碼 required
 * name: (type String(字串)) 活動名稱 required
 * started_at (type Date(日期)) 活動開始時間
 * ended_at (type Date(日期)) 結束時間
 * details (type String(字串) 活動詳情
 * rewards (type Array(陣列)) 活動獎勵內容 required
 * rewards.*.type (type String(字串)) 獎勵類別
 * rewards.*.amount (type Number(數字)) 獎勵額度
 * rewards.*.expired_at (type Date(日期)) 使用期限
 * status (type Number(數字)) 啟用狀態 required
 * }
 */
export const CampaignUpdateApi = (campaignID, form) => {
    return axios.post(`${apiConfig}/backyard/campaigns/${campaignID}`, form);
};

export default {
    GetCampaignsListApi,
    GetCampaignDataApi,
    CampaignCreateApi,
    CampaignUpdateApi,
};
