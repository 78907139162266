const exportExcelRouter = {
    path: "export-excel",
    name: "export-excel",
    icon: "fas fa-file-export",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "export-excel",
        text: "資料匯出",
        custom_route_name: "export-excel",
    },
    children: [
        {
            path: "",
            name: "export-excel",
            component: () => import("@/views/exportExcel/index.vue"),
            meta: {
                name: "export-excel",
                text: "報表匯出",
                parent: "export-excel",
                isMenu: false,
            },
        },
    ],
};

export { exportExcelRouter };
