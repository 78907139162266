import tw from "@/langs/tw.json";
import { setStorage, getStorage } from "@/service/localStorage";
import { firebaseConnectRef } from "@/plugins/firebase";
import { Message } from "element-ui";
// 預設語系
// 導入語系判斷方法
// import { checkLang } from "../service/anyService";
// 設定預設語系在localStorage
if (getStorage("lang") === null) {
    setStorage("lang", process.env.VUE_APP_DEFAULT_LANGUAGE);
}
export default {
    namespaced: true,
    state: {
        locale: getStorage("lang"),
        langDatas: tw,
        // status 錯時 要顯示對應的 錯誤訊息
        errorMessages: {},
    },
    getters: {
        langs: () => {
            const langData = [
                { lang: "cn", name: "简体中文", icon: "cn" },
                { lang: "en", name: "English", icon: "us" },
                { lang: "tw", name: "繁體中文", icon: "tw" },
                // { lang: "vn", name: checkLang(state.langData, "global", "langVn"), icon: "vn" },
            ];
            return langData;
        },
    },
    mutations: {
        setLang(state, lang) {
            state.locale = lang;
        },
        // 設定後臺語系檔
        setDefaultLang(state, langData) {
            state.langData = langData;
        },
        // 設定錯誤語系
        setErrorMessages(state, val) {
            state.errorMessages = val;
        },
    },
    actions: {
        async getErrorMessagesList({ commit }) {
            try {
                const data = await firebaseConnectRef("langs").child("errorStatus").get();
                // 判斷是否有訊息
                if (data.exists()) {
                    commit("setErrorMessages", data.val());
                }
            } catch (err) {
                console.log(err);
                Message({
                    type: "error",
                    message: "取得錯誤語系檔失敗",
                });
            }
        },
    },
};
