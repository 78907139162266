import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 上傳聊天室檔案api
 * @param { type File(檔案格式) } file 圖片或其他附件檔案（暫時只限定傳圖片）
 */
export const ChatUploadAttachmentsApi = (file) => {
    const result = new FormData();
    result.append("file", file.raw);
    return axios.post(`${apiConfig}/backyard/chat/attachments`, result);
};

export default {
    ChatUploadAttachmentsApi,
};
