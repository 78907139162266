import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 判斷 email 是否重複
 * @param { type String(字串) } email 信箱
 */
export const CheckEmailUniqueApi = (email) => {
    return axios.get(`${apiConfig}/auth/verify-email`, { params: { email } });
};

// 取得分類列表
export const GetCategoriesListApi = () => {
    return axios.get(`${apiConfig}/categories`);
};

export default {
    CheckEmailUniqueApi,
    GetCategoriesListApi,
};
