export default {
    namespaced: true,
    // data
    state: {
        // 儲存供給者資料
        providerData: {},
    },
    // computed
    getters: {},
    // methods
    mutations: {
        // 設定供給者資料
        setProviderData(state, val) {
            state.providerData = val;
        },
    },
    // mounted ajax的方法都寫在這
    actions: {},
};
