/* eslint-disable no-prototype-builtins */
/* eslint-disable no-empty-pattern */
// element ui alert 組件
import { Message } from "element-ui";
// 導入 firebase 連接資料庫機制
import { firebaseAuth, firebaseConnectRef } from "@/plugins/firebase";
/**
 * firebaseUnReadCountAndLastMessage: 新增聊天室 未讀訊息數量 與 最後一筆聊天內容
 * firebaseUpdateUserUnReadMessageCount: 更新users 中指定對象 所有未讀訊息數量
 */
import { firebaseUnReadCountAndLastMessage, firebaseUpdateUserUnReadMessageCount } from "@/service/firebaseChatSet";
// 計算 陣列裡 數字總合方法
import { sum as _sum } from "lodash/math";
// collection 指定 key 排序方法
// import { orderBy } from "lodash/collection";
// 判斷是否為 empty 方法
import { isEmpty } from "@/service/anyService";
import firebase from "firebase/app";
// lodash 過濾重複值方法
import { some } from "lodash/collection";
import moment from "moment";
export default {
    namespaced: true,
    state: {
        // 未讀聊天訊息數量
        unReadMessageCount: 0,
        // 會員未讀訊息數量統計
        unReadMessageCountByMember: 0,
        // 服務商未讀訊息數量統計
        unReadMessageCountByProvider: 0,
        // 客服聊天室 id
        serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
        // 聊天對象
        chatUsers: [],
        // 聊天對象分頁定位機制 用來判斷聊天對象要重哪個時段開始
        lastKey: null,
        // 設定服務商分頁 key
        lastKeyByProvider: null,
        // 更換顯示 tab
        chatTab: null,
        // 取得服務商資料
        providers: [],
    },
    getters: {
        // 即時的聊天訊息未讀數量
        realtimeUnReadMessageCount: (state) => {
            // 判斷未讀訊息數量是否大於 99
            if (state.unReadMessageCount > 99) {
                return "99+";
            }
            return state.unReadMessageCount;
        },
        // 即時的聊天對象
        realTimeChatUsers: (state) => {
            return state.chatUsers;
        },
        // 即時的服務商聊天對象
        realTimeProviderChatUsers: (state) => {
            return state.providers;
        },
    },
    mutations: {
        // 設定未讀聊天訊息數量總計
        setUnReadMessageCount(state, val) {
            state.unReadMessageCount = val;
        },
        // 設定會員未讀訊息數量
        setUnReadMessageCountByMember(state, val) {
            state.unReadMessageCountByMember = val;
        },
        // 設定服務商未讀訊息數量
        setUnReadMessageCountByProvider(state, val) {
            state.unReadMessageCountByProvider = val;
        },
        // 設定聊天對象中指定對象的未讀訊息數量以及未讀訊息時間
        setAssignChatUser(state, data) {
            // 判斷是否為取得服務商列表
            if (data.isGetProviders) {
                // 將未讀訊息更新為 0
                // state.chatUsers[data.index].unReadMessageCount = 0;
                // 更新已讀時間
                state.chatUsers[data.index].readedAt = data.readedAt;
            } else {
                // 將未讀訊息更新為 0
                state.chatUsers[data.index].unReadMessageCount = 0;
                // 更新已讀時間
                state.chatUsers[data.index].readedAt = data.readedAt;
            }
        },
        // 設定聊天對象
        setChatUsers(state, data) {
            // 判斷是否為取得服務商列表
            if (data.isGetProviders) {
                // 設定服務商資料
                state.providers = data.value;
            } else {
                // 設定聊天對象資料
                state.chatUsers = data.value;
            }
        },
        // 設定新增分頁聊天對象
        pushChatUsers(state, data) {
            // 判斷是否為取得服務商列表
            if (data.isGetProviders) {
                // 設定服務商資料
                state.providers.push(...data.value);
            } else {
                // 設定聊天對象資料
                state.chatUsers.push(...data.value);
            }
        },
        // 重置聊天對象資料
        setResetChatUsers(state) {
            state.chatUsers = [];
        },
        // 更新聊天對象對應 index 資料
        setChatUsersByIndex(state, data) {
            // 判斷是否為取得服務商列表
            if (data.isGetProviders) {
                // 設定服務商資料
                state.providers[data.index] = data.data;
            } else {
                // 設定聊天對象資料
                state.chatUsers[data.index] = data.data;
            }
        },
        // 設定聊天對象 分頁 key
        setLastKey(state, data) {
            // 判斷是否為取得服務商列表
            if (data.isGetProviders) {
                // 設定服務商列表分頁key
                state.lastKeyByProvider = data.lastKey;
            } else {
                // 設定聊天對象分頁key
                state.lastKey = data.lastKey;
            }
        },
        // 更換顯示 tab
        setChatTab(state, val) {
            state.chatTab = val;
        },
        // 設定服務商資料
        setProviders(state, val) {
            state.providers = val;
        },
        // 設定服務商智能客服
        setProviderCityAiValue(state, data) {
            state.providers[data.index].enableCityAi = data.enableCityAi;
        },
    },
    actions: {
        /**
         * 取得未讀訊息數量通知總數
         * @param { type String(字串) } userId 使用者的 banana_id
         */
        getUnReadMessageCount({ commit }, userId) {
            firebase.auth().onAuthStateChanged((user) => {
                if (user !== null) {
                    firebaseConnectRef("chat_rooms")
                        .child(userId)
                        .child("users")
                        .orderByChild("unReadMessageCount")
                        .on("value", async (snapshot) => {
                            // 判斷沒有聊天對象時 不往下執行
                            if (snapshot.val() === null) {
                                commit("setUnReadMessageCount", 0);
                                return;
                            }
                            const count = Object.keys(snapshot.val()).map((objKey) => {
                                // 計算未讀訊息數量 (判斷是否有未讀訊息數量 沒數量回應 0)
                                return !snapshot.val()[objKey].hasOwnProperty("unReadMessageCount") ? 0 : snapshot.val()[objKey].unReadMessageCount;
                            });
                            const memberCount = [];
                            const providerCount = [];
                            snapshot.forEach((item) => {
                                // 判斷有 userData key 才往下執行
                                if (item.val().userData !== undefined) {
                                    if (item.val().userData.role == 0 && item.val().unReadMessageCount > 0) {
                                        memberCount.push(item.val().unReadMessageCount);
                                    } else if (item.val().userData.role != 0 && item.val().unReadMessageCount > 0) {
                                        providerCount.push(item.val().unReadMessageCount);
                                    }
                                }
                            });
                            // 設定會員未讀訊息數量
                            commit("setUnReadMessageCountByMember", _sum(memberCount));
                            //  設定服務商未讀訊息數量
                            commit("setUnReadMessageCountByProvider", _sum(providerCount));
                            // 設定未讀訊息總計
                            commit("setUnReadMessageCount", _sum(count));
                        });
                }
            });
        },
        /**
         * 設定聊天對象資料
         * @param {*} param0
         * @param { type Object(物件) } datas firebase 聊天對象列表的分頁資料
         * @returns
         */
        async setChatUsersData({}, datas) {
            const users = [];

            return new Promise((resolve) => {
                datas.forEach((item) => {
                    // 使用者資料
                    const user = item.val().userData ?? {};
                    // 最c後一筆聊天室內容訊息 (判斷是否有訊息 沒訊息 回應空字串)
                    const message = isEmpty(item.val().message) ? "" : item.val().message;
                    // 計算未讀訊息數量 (判斷是否有未讀訊息數量 沒數量回應 0)
                    const count = !item.val().hasOwnProperty("unReadMessageCount") ? 0 : item.val().unReadMessageCount;
                    // 計算未讀訊息數量
                    user["unReadMessageCount"] = count;
                    // 最後一筆聊天室內容訊息
                    user.message = message;
                    // 最後一筆訊息時間
                    user.lastMsgAt = isEmpty(item.val().lastMsgAt) ? null : Math.abs(item.val().lastMsgAt);
                    // 最後更新資料時間
                    user.updatedAt = item.val().updatedAt === undefined ? moment().valueOf() + 100 : Math.abs(item.val().updatedAt);
                    // 已讀訊息時間
                    user.readedAt = item.val().readedAt ?? 0;
                    // 是否為機器人回應
                    user.isBot = item.val().isBot === undefined ? false : item.val().isBot;
                    // 判斷是否在線
                    user.isOnline = item.val().status === "online" ? true : false;
                    // 判斷是否啟用  city ai 智能客服
                    user.enableCityAi = item.val().enableCityAi ?? false;
                    users.push(user);
                });
                resolve(users);
            });
        },
        // 取得服務商使用者
        async getUsers({ commit, dispatch }, data) {
            const query = data.firebaseQuery;
            return new Promise((resolve, reject) => {
                firebaseConnectRef("users")
                    .orderByChild("updatedAt")
                    .limitToFirst(150)
                    .on("value", async (snapshot) => {
                        // 判斷沒有聊天對象時 不往下執行
                        if (snapshot.val() === null) {
                            reject(false);
                            commit("setChatUsers", { isGetProviders: data.isGetProviders, value: [] });
                            return;
                        }
                        let users = await dispatch("setChatUsersData", snapshot);
                        users = users.filter((item) => item.updatedAt !== null || item.updatedAt !== undefined);
                        const lastKey =
                            -users[users.length - 1].updatedAt === undefined
                                ? -users[users.length - 1].created_at
                                : -users[users.length - 1].updatedAt;

                        commit("setLastKey", { isGetProviders: data.isGetProviders, lastKey: lastKey });
                        // 判斷目前是選擇 會員聊天列表 還是 服務商 過濾出對應的聊天對象
                        const filterUsers = users.filter((item) => {
                            if (query === "members") {
                                // 當 role 等於 0 時代表為會員身份
                                return item.role === 0;
                            }
                            // 當 role 等於 1 或 2 時代表為服務商身份
                            return item.role > 0;
                        });

                        // 判斷過濾完後沒有對象時 再取的下一分頁 新的聊天對象
                        if (filterUsers.length === 0) {
                            await dispatch("getChatUsersPagination", {
                                isGetProviders: true,
                                userId: data.userId,
                                firebaseQuery: query,
                                lastKey: -users[users.length - 1].updatedAt,
                            });
                            resolve(true);
                            return;
                        }
                        commit("setChatUsers", { isGetProviders: data.isGetProviders, value: filterUsers });
                        resolve(true);
                    });
            });
        },
        /**
         * 聊天對象資料
         * @param {*} param0
         * @param {type String(字串) } data 登入使用者與聊天對象 id
         * @example {
         * userId: "",
         * currentReceiveUserId: "",
         * firebaseQuery: "members" or "providers" 判斷身份是服務商還是會員 用來過濾出 聊天對象為服務商或會員 }
         */
        async getChatUsers({ commit, dispatch }, data) {
            const query = data.firebaseQuery;
            return new Promise((resolve, reject) => {
                firebaseConnectRef("chat_rooms")
                    .child(data.userId)
                    .child("users")
                    .orderByChild("lastMsgAt")
                    .limitToFirst(50)
                    .on("value", async (snapshot) => {
                        // 判斷沒有聊天對象時 不往下執行
                        if (snapshot.val() === null) {
                            reject(false);
                            commit("setChatUsers", { isGetProviders: data.isGetProviders, value: [] });
                            return;
                        }
                        let users = await dispatch("setChatUsersData", snapshot);
                        users = users.filter((item) => item.lastMsgAt !== null);
                        commit("setLastKey", { isGetProviders: data.isGetProviders, lastKey: -users[users.length - 1].lastMsgAt });
                        // 判斷目前是選擇 會員聊天列表 還是 服務商 過濾出對應的聊天對象
                        const filterUsers = users.filter((item) => {
                            if (query === "members") {
                                // 當 role 等於 0 時代表為會員身份
                                return item.role === 0;
                            }
                            // 當 role 等於 1 或 2 時代表為服務商身份
                            return item.role > 0;
                        });
                        // 判斷過濾完後沒有對象時 再取的下一分頁 新的聊天對象
                        if (filterUsers.length === 0) {
                            await dispatch("getChatUsersPagination", {
                                isGetProviders: false,
                                userId: data.userId,
                                firebaseQuery: query,
                                lastKey: -users[users.length - 1].lastMsgAt,
                            });
                            resolve(true);
                            return;
                        }
                        commit("setChatUsers", { isGetProviders: data.isGetProviders, value: filterUsers });
                        resolve(true);
                    });
            });
        },

        /**
         * 分頁聊天對象資料取得
         * @param {*} param0
         * @param {type String(字串) } data 登入使用者與聊天對象 id
         * @example {
         * userId: "",
         * currentReceiveUserId: "",
         * firebaseQuery: "members" or "providers" 判斷身份是服務商還是會員 用來過濾出 聊天對象為服務商或會員 }
         * lastKey: 分頁資料定位 key
         */
        async getChatUsersPagination({ commit, dispatch }, data) {
            // 判斷選中tab是否為服務商 如果為服務商 因服務商 role 值 落在 1跟2 之間 會員值為 0 因此搜尋參數為以下
            const query = data.firebaseQuery;
            let datas = null;
            // 分頁 key
            const paginationKey = data.isGetProviders ? "updatedAt" : "lastMsgAt";
            try {
                if (data.isGetProviders) {
                    datas = await firebaseConnectRef("users").orderByChild("updatedAt").startAfter(data.lastKey).limitToFirst(50).once("value");
                } else {
                    datas = await firebaseConnectRef("chat_rooms")
                        .child(data.userId)
                        .child("users")
                        .orderByChild("lastMsgAt")
                        .startAfter(data.lastKey)
                        .limitToFirst(30)
                        .once("value");
                }
                // 判斷沒有聊天對象時 不往下執行
                if (datas.val() === null) {
                    console.log("聊天對象以沒最新資料", data.lastKey, data.isGetProviders);
                    commit("setLastKey", { isGetProviders: data.isGetProviders, lastKey: "end" });
                    return;
                }
                let users = await dispatch("setChatUsersData", datas);
                users = users.filter((item) => item[paginationKey] !== null);
                // 判斷最後一個 lastKey 跟 上一次的最後一個 lastKey 一樣時 取消載入分頁
                // 因為代表過濾完後已經沒有更新的資料了
                if (data.lastKey === -users[users.length - 1][paginationKey]) {
                    commit("setLastKey", { isGetProviders: data.isGetProviders, lastKey: "end" });
                    return;
                }
                commit("setLastKey", { isGetProviders: data.isGetProviders, lastKey: -users[users.length - 1][paginationKey] });
                // 判斷目前是選擇 會員聊天列表 還是 服務商 過濾出對應的聊天對象
                let filterUsers = users.filter((item) => {
                    if (query === "members") {
                        // 當 role 等於 0 時代表為會員身份
                        return item.role === 0;
                    }
                    // 當 role 等於 1 或 2 時代表為服務商身份
                    return item.role > 0;
                });
                // 判斷過濾完後時否還有聊天對象
                if (filterUsers.length > 0) {
                    // 過濾重複的聊天對象
                    filterUsers = filterUsers.filter((item) => {
                        return !some(data.allUsers, { banana_id: item.banana_id });
                    });
                    /**
                     * 當過濾完重複對象的使用者時 沒有新的使用者時
                     * 需將 lastKey設定為 end 代表為 最後一頁k
                     * 不然會無限遞迴
                     */
                    if (filterUsers.length === 0) {
                        commit("setLastKey", { isGetProviders: data.isGetProviders, lastKey: "end" });
                    } else {
                        commit("pushChatUsers", { isGetProviders: data.isGetProviders, value: filterUsers });
                    }
                } else {
                    console.log("// 沒有聊天對象時要觸發載入分頁 不然會變成空的聊天對象");
                    // 沒有聊天對象時要觸發載入分頁 不然會變成空的聊天對象
                    await dispatch("getChatUsersPagination", {
                        isGetProviders: data.isGetProviders,
                        userId: data.userId,
                        firebaseQuery: query,
                        lastKey: -users[users.length - 1][paginationKey],
                        allUsers: data.allUsers,
                    });
                }
            } catch (err) {
                console.log(err);
                return { err };
            }
        },
        // 判斷排序是否有變化
        async checkChatUsersSortChange({ commit }, data) {
            firebaseConnectRef("chat_rooms")
                .child(data.userId)
                .child("users")
                .orderByChild("lastMsgAt")
                .on("child_moved", (snapshot) => {
                    if (snapshot.val().isProvider) {
                        commit("setChatTab", "providers");
                    } else {
                        commit("setChatTab", "members");
                    }
                });
        },
        /**
         *搜尋聊天對象
         * @param { type Object(物件) } data 搜尋條件資料
         * @example {
         * userId: "", 登入者 聊天室 id
         * name: "", 搜尋聊天對象中稱
         * }
         * @returns
         */
        async searchChatUsers({ commit, dispatch }, data) {
            const query = data.firebaseQuery;
            // 分頁 key
            const paginationKey = data.isGetProviders ? "updatedAt" : "lastMsgAt";
            let datas = null;
            try {
                if (data.isGetProviders) {
                    // datas = await firebaseConnectRef("users").orderByChild("updatedAt").startAfter(data.lastKey).limitToFirst(30).once("value");
                    datas = await firebaseConnectRef("users")
                        .orderByChild("userData/name")
                        .startAt(data.name)
                        .endAt(data.name + "\uf8ff")
                        .once("value");
                    if (datas.val() === null) {
                        console.log("沒有找到聊天對象");

                        commit("setChatUsers", { isGetProviders: data.isGetProviders, value: [] });
                    }
                } else {
                    datas = await firebaseConnectRef("chat_rooms")
                        .child(data.userId)
                        .child("users")
                        .orderByChild("userData/name")
                        .startAt(data.name)
                        .endAt(data.name + "\uf8ff")
                        .once("value");
                    if (datas.val() === null) {
                        console.log("沒有找到聊天對象");

                        commit("setChatUsers", { isGetProviders: data.isGetProviders, value: [] });
                    }
                }
                let users = await dispatch("setChatUsersData", datas);
                // 判斷目前是選擇 會員聊天列表 還是 服務商 過濾出對應的聊天對象
                users = users.filter((item) => {
                    if (query === "members") {
                        // 當 role 等於 0 時代表為會員身份
                        return item.role === 0;
                    }
                    // 當 role 等於 1 或 2 時代表為服務商身份
                    return item.role > 0;
                });
                users = users.filter((item) => item[paginationKey] !== null);
                commit("setChatUsers", { isGetProviders: data.isGetProviders, value: users });
            } catch (err) {
                return { err };
            }
        },
        /**
         * 取得 firebase admin token
         * @param { type String(字串) } userId 使用者 banana_id
         */
        async getFirebaseToken({ dispatch }, userId) {
            try {
                const {
                    data: { token },
                } = await this._vm.$api.GetFirebaseCustomTokenApi(userId);
                await dispatch("loginFirebaseAuth", token);
                return token;
            } catch (err) {
                Message({
                    type: "error",
                    message: "取得firebase admin token 失敗",
                });
            }
        },
        /**
         * 登入firebase使用者
         */
        async loginFirebaseAuth({}, token) {
            try {
                await firebaseAuth().signInWithCustomToken(token);
            } catch (err) {
                Message({
                    type: "error",
                    message: "firebase 登入失敗",
                });
            }
        },
        /**
         * 登出 firebase 使用者
         */
        async logoutFirebaseAuth() {
            try {
                await firebaseAuth().signOut();
            } catch (err) {
                Message({
                    type: "error",
                    message: "firebase 登出失敗",
                });
            }
        },
        /**
         * 設定訊息未讀數量歸 0
         * @param {*} param0
         * @param { type String(字串)} currentReceiveUserId 聊天對象 id
         * @param { type Bool(布林) } isNotServiceChat 判斷是否為客服聊天對象
         * @param { type String(字串) } userId 擁有聊天室的使用者 id
         */
        async setCurrentReceiveUserMessageReaded({}, { allUsers, currentReceiveUserId, serviceChatId }) {
            const findUser = allUsers.findIndex((item) => item.banana_id === currentReceiveUserId);
            if (findUser !== -1) {
                try {
                    await firebaseConnectRef("chat_rooms").child(serviceChatId).child("users").child(currentReceiveUserId).update({
                        unReadMessageCount: 0,
                        updatedAt: firebase.database.ServerValue.TIMESTAMP,
                    });

                    return { success: true };
                } catch (err) {
                    return { err };
                }
            }
        },
        /**
         * 設定聊天對象 未讀訊息數量 以及 最後一個 訊息內容
         * @param { type Number(數字) }  unReadCount 未讀訊息數量
         * @param { type Number(數字) } lastMsgAt 最後一筆聊天室訊息內容時間
         * @param { type String(字串) } lastMessage 最後一筆聊天室訊息內容
         * @param { type String(字串) } loginUserId 登入使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象使用者 id
         * @param { type Boolean(布林) } needUpdateUserUnReadMessageCount 判斷是否更新users未讀訊息數量
         */
        async setUnReadCountAndLastMessage(
            {},
            { unReadCount, lastMsgAt, lastMessage, loginUserId, receiveUserId, needUpdateUserUnReadMessageCount }
        ) {
            // 傳送資料
            const data = {
                unReadMessageCount: unReadCount,
                message: lastMessage,
                lastMsgAt: -lastMsgAt,
            };
            await firebaseUnReadCountAndLastMessage(data, loginUserId, receiveUserId);
            // 判斷是否要更新未讀訊息數量 因為發送訊息時 可以不用更新自己的 users 未讀訊息數量
            if (needUpdateUserUnReadMessageCount) {
                await firebaseUpdateUserUnReadMessageCount(loginUserId, receiveUserId);
            }
        },
    },
};
