const chatControlRouter = {
    path: "/chat_control",
    name: "chat_control",
    icon: "fas fa-comment-alt",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "chat_control",
        text: "聊天",
    },
    children: [
        {
            path: "/chats-cityai",
            name: "chats-cityai",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/chats/cityai/index.vue"),
            meta: {
                name: "chats-cityai",
                parent: "chat_control",
                text: "CityAi智能客服",
                icon: "fas fa-comment-alt",
                isMenu: true,
            },
        },
        {
            path: "/chats/:tab?/:id?",
            name: "chats",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/chats/index.vue"),
            meta: {
                name: "chats",
                parent: "chat_control",
                text: "聊天室",
                icon: "fas fa-comment-alt",
                isMenu: true,
            },
        },
        {
            path: "/chats-faq-set",
            name: "chats-faq-set",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/chats/faqSet/index.vue"),
            meta: {
                name: "chats-faq-set",
                parent: "chat_control",
                text: "機器人設定",
                icon: "fas fa-comment-alt",
                isMenu: true,
            },
        },
        {
            path: "/chats-notify",
            name: "chats-notify",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/chats/notify/index.vue"),
            meta: {
                name: "chats-notify",
                parent: "chat_control",
                text: "聊天室推播",
                icon: "fas fa-comment-alt",
                isMenu: true,
            },
        },
        // {
        //     path: "/firestore",
        //     name: "firestore",
        //     icon: "fas fa-comment-alt",
        //     component: () => import("@/views/firestore/index.vue"),
        //     meta: {
        //         name: "firestore",
        //         parent: "chat_control",
        //         text: "Firestore轉移工具",
        //         icon: "fas fa-comment-alt",
        //         isMenu: true,
        //     },
        // },
        // {
        //     path: "/chats-test",
        //     name: "chats-test",
        //     icon: "fas fa-comment-alt",
        //     component: () => import("@/views/chats/test.vue"),
        //     meta: {
        //         name: "chats-faq-set",
        //         parent: "chat_control",
        //         text: "聊天室測試",
        //         icon: "fas fa-comment-alt",
        //         isMenu: true
        //     }
        // }
    ],
};

export { chatControlRouter };
