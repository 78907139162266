import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 路由權限列表
 */
export const GetPermissionsApi = () => {
    return axios.get(`${apiConfig}/backyard/permissions`, {
        params: {
            root_only: 1,
        },
    });
};

/**
 * 新增權限路由
 * @example {
    "parent_id": "[integer, default null]",
    "key": "[required | string]",
    "name": "[required | string]",
    "customer_key": "[string, default null]",
    "is_menu": "[boolean, default 0]",
    "is_child_feature": "[boolean, default 0]",
    "setting": "[json object]",
    "child_features": "[json array]",
    "child_features.*": "[json object]",
    "api": "[json object]"
 * }
 * @returns
 */
export const CreatePermissionsApi = (params) => {
    return axios.post(`${apiConfig}/backyard/permissions`, params);
};

/**
 * 取得單一路由權限
 */
export const GetPermissionsIDApi = (id) => {
    return axios.get(`${apiConfig}/backyard/permissions/${id}`);
};

/**
 * 更新權限路由
 * @example {
    "parent_id": "[integer, default null]",
    "key": "[required | string]",
    "name": "[required | string]",
    "customer_key": "[string, default null]",
    "is_menu": "[boolean, default 0]",
    "is_child_feature": "[boolean, default 0]",
    "setting": "[json object]",
    "child_features": "[json array]",
    "child_features.*": "[json object]",
    "api": "[json object]"
 * }
 * @returns
 */

export const UpdatePermissionsApi = (id, params) => {
    return axios.put(`${apiConfig}/backyard/permissions/${id}`, params);
};

/**
 * 刪除路由權限
 * @param { type ID } id
 */
export const DeletePermissionsApi = (id) => {
    return axios.delete(`${apiConfig}/backyard/permissions/${id}`);
};

/**
 * 角色列表
 */
export const GetRolesApi = (params) => {
    return axios.get(`${apiConfig}/backyard/roles`, { params });
};

export const GetSingleRoleApi = (id) => {
    return axios.get(`${apiConfig}/backyard/roles/${id}`);
};

/**
 * 新增角色
 * @param { type ID } id
 */
export const CreateRoleApi = (params) => {
    return axios.post(`${apiConfig}/backyard/roles`, params);
};

/**
 * 編輯角色
 * @param { type ID } id
 */
export const UpdateRoleApi = (id, params) => {
    return axios.put(`${apiConfig}/backyard/roles/${id}`, params);
};

/**
 * 刪除角色
 * @param { type ID } id
 */
export const DeleteRoleApi = (id) => {
    return axios.delete(`${apiConfig}/backyard/roles/${id}`);
};

/**
 * 新增與更新角色權限
 * @param { type String or Number(字串或數字) } id 角色id
 * @param { type Array(陣列) } permissions 權限設定值
 */
export const SetPermissionApi = (id, permissions) => {
    return axios.post(`${apiConfig}/backyard/roles/${id}/permissions`, { permissions: permissions });
};

/**
 *  取得人員列表
 */
export const GetMembersApi = (params) => {
    return axios.get(`${apiConfig}/backyard/administrators`, { params });
};

/**
 * 取得單筆人員
 * @param { type ID } id
 */
export const GetSingleMembersApi = (id) => {
    return axios.get(`${apiConfig}/backyard/administrators/${id}`);
};

/**
 * 新增人員
 * @param { type ID } id
 * @param { type String or Number(字串或數字) } name
 * @param { type String or Number(字串或數字) } password
 * @param { type String or Number(字串或數字) } comment
 * @param { [ { role_id : 0} ]  } roles 賦予角色
 */
export const CreateMemberApi = (params) => {
    return axios.post(`${apiConfig}/backyard/administrators`, params);
};

/**
 * 編輯人員
 * @param { type ID } id
 * @param { type String or Number(字串或數字) } name
 * @param { type String or Number(字串或數字) } password
 * @param { type String or Number(字串或數字) } comment
 * @param { [ { role_id : 0} ] } roles 賦予角色
 */
export const UpdateMemberApi = (id, params) => {
    return axios.put(`${apiConfig}/backyard/administrators/${id}`, params);
};

/**
 * 刪除人員
 * @param { type ID } id
 */
export const DeleteMemberApi = (id) => {
    return axios.delete(`${apiConfig}/backyard/administrators/${id}`);
};

export const GetAllApi = () => {
    return axios.get(`${apiConfig}/backyard/routers`);
};

export default {
    GetPermissionsApi,
    CreatePermissionsApi,
    DeletePermissionsApi,
    GetPermissionsIDApi,
    UpdatePermissionsApi,
    GetRolesApi,
    GetSingleRoleApi,
    DeleteRoleApi,
    CreateRoleApi,
    UpdateRoleApi,
    SetPermissionApi,
    GetMembersApi,
    GetSingleMembersApi,
    CreateMemberApi,
    UpdateMemberApi,
    DeleteMemberApi,
    GetAllApi,
};
