import Vue from "vue";
import Router from "vue-router";
import { getStorage } from "@/service/localStorage";
import store from "@/store";
import brokerRouter from "@/router/routers/brokerRouter";

Vue.use(Router);

let router = null;

export default function setBrokerRouter() {
    // 初始路由
    router = new Router(brokerRouter);
    router.beforeEach(async (to, from, next) => {
        // 獲取登入token從 localStorage
        const isLogin = getStorage("token") !== null ? true : false;
        // 判斷是否為登入頁 如果是的話繼續
        if (!isLogin && to.name !== "login") {
            next({ name: "login" });
        } else {
            if (Object.keys(store.state.userStore.user).length <= 0) {
                await store.dispatch("userStore/getLoginBrokerInfo");
            }
            next();
        }
    });
    return router;
}
