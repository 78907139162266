import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 取得 firebase 客製化 token api
 * @param { type String(字串) } userId 始用者id
 */
export const GetFirebaseCustomTokenApi = () => {
    // nuxt 的 api 暫時測試用
    // return axios.post("https://banana.com/firebaseAdmin/getToken", {userId});
    return axios.post(`${apiConfig}/backyard/chat/tokens`);
};

export default {
    GetFirebaseCustomTokenApi,
};
