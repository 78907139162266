const systemControlRouter = {
    path: "/system_control",
    name: "system_control",
    icon: "fas fa-tools",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "system_control",
        text: "系統",
    },
    children: [
        {
            path: "error_lang",
            name: "error_lang",
            component: () => import("@/views/systemControl/errorLangSet.vue"),
            meta: {
                text: "錯誤訊息設定",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "register_message_set",
            name: "register_message_set",
            component: () => import("@/views/systemControl/registerMessageSet.vue"),
            meta: {
                text: "註冊發送訊息",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "provider_default_message",
            name: "provider_default_message",
            component: () => import("@/views/systemControl/providerDefaultMessage.vue"),
            meta: {
                text: "服務商預設訊息樣板",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "product_versions",
            name: "product_versions",
            component: () => import("@/views/systemControl/productVersions.vue"),
            meta: {
                text: "設定產品版本號",
                parent: "system_control",
                isMenu: true,
            },
        },
        // {
        //     path: "resize_image",
        //     name: "resize_image",
        //     component: () => import("@/views/systemControl/testResizeImage.vue"),
        //     meta: {
        //         text: "重製圖片尺寸",
        //         parent: "system_control",
        //         isMenu: true
        //     }
        // }
    ],
};

export { systemControlRouter };
