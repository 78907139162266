import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 客訴單列表
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 * limit: items per page, default is 30
 * id: user feedback identity
 * administrator_id: administrator identity
 * user_name: user's name
 * user_phone: user's phone number
 * created_at_start: the min date of created_at
 * created_at_end: the max date of created_at
 * type: user feedback type
 * severity: user feedback severity
 * status: user feedback status
 * sort_by: created_at only
 * sort_type: asc or desc
 * }
 * @returns
 */

const SearchUserFeedbacks = (params) => {
    return axios.get(`${apiConfig}/backyard/user-feedbacks`, { params });
};

/**
 * 新增客訴單
 * @param { type Object(物件) } params 客訴單資訊
 * @example {
 * phone: 客訴者號碼
 * defendant_phone: 被客訴者號碼
 * type: 客訴類型
 * severity: 重要性 [0,1,2]
 * feedback: 敘述
 * images: 圖片
 * order_id: 訂單編號
 * }
 * @returns
 */

const CreateUserFeedback = (data) => {
    return axios.post(`${apiConfig}/backyard/user-feedbacks`, data);
};

const GetUserFeedback = (id) => {
    return axios.get(`${apiConfig}/backyard/user-feedbacks/${id}`);
};

/**
 * 編輯客訴單
 * @param { type String } id 客訴單編號
 * @param { type Object(物件) } params 客訴單資訊
 * @example {
 * phone: 客訴者號碼
 * defendant_phone: 被客訴者號碼
 * type: 客訴類型
 * severity: 重要性 [0,1,2]
 * feedback: 敘述
 * images: 圖片
 * order_id: 訂單編號
 * }
 * @returns
 */

const UpdateUserFeeback = (id, params) => {
    return axios.post(`${apiConfig}/backyard/user-feedbacks/${id}`, params);
};

/**
 * 新增註記
 * @param { type String } id 客訴單編號
 * @param { type Object(物件) } params 客訴單資訊
 * @example {
 * note: 描述
 * significant: [boolean] 0|1
 * }
 * @returns
 */

const UpdatFeebackLog = (id, params) => {
    return axios.post(`${apiConfig}/backyard/user-feedbacks/${id}/support-logs`, params);
};

/**
 * 取得客訴單狀態更新LOGS
 * @param { type id } id 訂單編號
 */

const GetFeedbackLogs = (id) => {
    return axios.get(`${apiConfig}/backyard/user-feedbacks/${id}/status-logs`);
};

export default {
    SearchUserFeedbacks,
    CreateUserFeedback,
    GetUserFeedback,
    UpdateUserFeeback,
    UpdatFeebackLog,
    GetFeedbackLogs,
};
