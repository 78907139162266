import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * @returns 取得所有 banner
 */

// Banner
const GetBannerList = () => {
    return axios.get(`${apiConfig}/backyard/expo/banners`);
};

/**
 * @param { type String } title
 * @param { type String } position [required | in:header,body]
 * @param { type Number } category [required_if:position,body | integer | between:1,4]
 * @param { type String } target [required | url]
 * @param { type Boolean } duration [required | boolean] 使用期間開啟
 * @param { type String } start [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type String } end [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type Blob } dimage [image:jpeg,png,jpg,gif,svg | max:5120] 桌機版
 * @param { type Blob } mimage [image:jpeg,png,jpg,gif,svg | max:5120] 手機版
 * @param { type Boolean } activated [required | boolean] 啟用
 * @returns 更新banner
 */

const UpdateExpoBanner = (id, data) => {
    return axios.post(`${apiConfig}/backyard/expo/banners/${id}`, data);
};

/**
 * @param { type String } id banner id
 * @param { type String } position [required | in:header,body]
 * @returns 刪除 banner
 */

const DeleteBanner = (position, id) => {
    return axios.delete(`${apiConfig}/backyard/expo/banners/${position}/${id}`);
};

/**
 * @param { type String } title
 * @param { type String } position [required | in:header,body]
 * @param { type Number } category [required_if:position,body | integer | between:1,4]
 * @param { type String } target [required | url]
 * @param { type Boolean } duration [required | boolean] 使用期間開啟
 * @param { type String } start [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type String } end [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type Blob } dimage [image:jpeg,png,jpg,gif,svg | max:5120] 桌機版
 * @param { type Blob } mimage [image:jpeg,png,jpg,gif,svg | max:5120] 手機版
 * @param { type Boolean } activated [required | boolean] 啟用
 * @returns 新增 banner
 */

const CreateExpoBanner = (id, data) => {
    return axios.post(`${apiConfig}/backyard/expo/banners`, data);
};

/**
 * @returns 猜你喜歡
 */

const GetNewbiesList = () => {
    return axios.get(`${apiConfig}/backyard/expo/providers/newbies`);
};

/**
 * @param { type String } user_id
 * @param { type Boolean } duration [required | boolean] 使用期間開啟
 * @param { type String } start [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type String } end [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @returns 新增猜你喜歡
 */

const CreatNewbies = (data) => {
    return axios.post(`${apiConfig}/backyard/expo/providers/newbies`, data);
};

/**
 * @param { type Boolean } duration [required | boolean] 使用期間開啟
 * @param { type String } start [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type String } end [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type String } activated [required | boolean]
 * @returns 編輯猜你喜歡
 */

const UpdateNewbies = (id, params) => {
    return axios.patch(`${apiConfig}/backyard/expo/providers/newbies/${id}`, params);
};

/**
 * @param { type String } bananaID
 * @returns 刪除猜你喜歡
 */

const DeleteNewbies = (bananaID) => {
    return axios.delete(`${apiConfig}/backyard/expo/providers/newbies/${bananaID}`);
};

/**
 * @returns 推廣大圖
 */

const GetPromoList = () => {
    return axios.get(`${apiConfig}/backyard/expo/providers/selected`);
};

/**
 * @param { type String } category [required | integer | between:1,4]
 * @param { type String } user_id [required | integer]
 * @param { type Boolean } duration [required | boolean] 使用期間開啟
 * @param { type String } start [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type String } end [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type Blob } image [image:jpeg,png,jpg,gif,svg | max:5120]
 * @returns 新增推廣大圖
 */

const CreatePromo = (data) => {
    return axios.post(`${apiConfig}/backyard/expo/providers/selected`, data);
};

/**
 * @param { type String } category [required | integer | between:1,4]
 * @param { type Boolean } duration [required | boolean] 使用期間開啟
 * @param { type String } start [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type String } end [required_if:duration,true | date_format:Y-m-d H:i:s]
 * @param { type String } activated [required | boolean]
 * @param { type Blob } image [image:jpeg,png,jpg,gif,svg | max:5120]
 * @returns 更新推廣大圖
 */

const UpdatePromo = (id, data) => {
    return axios.post(`${apiConfig}/backyard/expo/providers/selected/${id}`, data);
};

/**
 * @param { type String } bananaID
 * @returns 刪除推廣大圖
 */

const DeletePromo = (bananaID) => {
    return axios.delete(`${apiConfig}/backyard/expo/providers/selected/${bananaID}`);
};

/**
 * @param { type String } bananaID
 * @returns Footer設定
 */

const GetFooterSetting = () => {
    return axios.get(`${apiConfig}/backyard/expo/footer`);
};

/**
 * @param { type String } data
 * [
        {
            "title": "社群",
            "child": [
                {
                    "label": "安全及信賴",
                    "link": "https://blog.citybanana.com/?page_id=1393"
                },
            ]
        }
    ]
 * @returns 更新Footer
 */

const UpdateFooterSetting = (data) => {
    return axios.post(`${apiConfig}/backyard/expo/footer`, data);
};

/**
 * @param { type String } text [required | string | max:100]
 * @param { type String } url [nullable | url]
 * @param { type String } start_date [date_format:Y-m-d]
 * @param { type String } end_date [date_format:Y-m-d]
 * @returns 新增最新消息
 */

const CreateNew = (data) => {
    return axios.post(`${apiConfig}/backyard/expo/news`, data);
};

// 最新消息
const GetNewsList = () => {
    return axios.get(`${apiConfig}/backyard/expo/news`);
};

// 獲取單個最新消息
const GetNewID = (id) => {
    return axios.get(`${apiConfig}/backyard/expo/news/${id}`);
};

/**
 * @param { type String } text [required | string | max:100]
 * @param { type String } url [nullable | url]
 * @param { type String } start_date [date_format:Y-m-d]
 * @param { type String } end_date [date_format:Y-m-d]
 * @returns 更新最新消息
 */
const UpdateNew = (id, data) => {
    return axios.put(`${apiConfig}/backyard/expo/news/${id}`, data);
};

// 刪除最新消息
const DeleteNew = (id) => {
    return axios.delete(`${apiConfig}/backyard/expo/news/${id}`);
};

// 教戰守則
const GetGuidelinesList = (params) => {
    return axios.get(`${apiConfig}/backyard/expo/guidelines`, { params });
};

// 取得置頂教戰守則
const GetGuidelinePinned = () => {
    return axios.get(`${apiConfig}/backyard/expo/guidelines/pinned`);
};

// 設置為置頂教戰守則
const UpdatePinnedGuideline = (data) => {
    return axios.patch(`${apiConfig}/backyard/expo/guidelines/pinned`, data);
};

/**
 * @param { type Number } category "[required | integer]",
 * @param { type String } text [required | string | max:100]
 * @param { type String } url [nullable | url]
 * @param { type String } start_date [date_format:Y-m-d]
 * @param { type String } end_date [date_format:Y-m-d]
 * @param { type Boolean } pin [boolean]
 * @returns 新增教戰守則
 */

const CreateGuideline = (data) => {
    return axios.post(`${apiConfig}/backyard/expo/guidelines`, data);
};

// 獲取單個教戰守則
const GetGuidelineID = (id) => {
    return axios.get(`${apiConfig}/backyard/expo/guidelines/${id}`);
};

/**
 * @param { type Number } category "[required | integer]",
 * @param { type String } text [required | string | max:100]
 * @param { type String } url [nullable | url]
 * @param { type String } start_date [date_format:Y-m-d]
 * @param { type String } end_date [date_format:Y-m-d]
 * @param { type Boolean } pin [boolean]
 * @returns 更新教戰守則
 */
const UpdateGuidelines = (id, data) => {
    return axios.put(`${apiConfig}/backyard/expo/guidelines/${id}`, data);
};

// 刪除教戰守則
const DeleteGuideline = (id) => {
    return axios.delete(`${apiConfig}/backyard/expo/guidelines/${id}`);
};

// 取得教戰守則類型
const getGategories = () => {
    return axios.get(`${apiConfig}/categories/guidelines`);
};

export default {
    GetBannerList,
    GetNewbiesList,
    GetPromoList,
    CreatePromo,
    UpdatePromo,
    DeletePromo,
    CreateExpoBanner,
    UpdateExpoBanner,
    DeleteBanner,
    CreatNewbies,
    UpdateNewbies,
    DeleteNewbies,
    GetFooterSetting,
    UpdateFooterSetting,
    GetNewsList,
    GetNewID,
    UpdateNew,
    DeleteNew,
    CreateNew,
    GetGuidelinesList,
    GetGuidelinePinned,
    UpdatePinnedGuideline,
    CreateGuideline,
    GetGuidelineID,
    UpdateGuidelines,
    DeleteGuideline,
    getGategories,
};
