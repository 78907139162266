import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 取得服務商列表
 * @param { type Object(物件) } params 過濾參數
 * @example {
 * page: (type Number(數字)) 分頁頁碼
 * limie: 20 (type Number) 單頁呈現幾筆資料,
 * roles: [1,2] (type Array) 0 = 會員 1 = 已上架服務商 2 = 已下架服務商,
 * sort_by: (type String) 排序依據key,
 * sort_type: (type String) desc or asc 倒序或正序 (desc 倒序 asc 正序)
 * name: 暱稱 type String(字串)
 * real_name: 真實姓名 type String(字串)
 * birthday: 生日  type String(字串) ex:"05-05"
 * phone: 手機號碼 type String(字串) ex:886912321321
 * status: 啟用狀態 type String or Number(字串或數字) 0: 使用中 1 停權 2 永久停權
 * government_id: 身份證字號 type String(字串)
 * banana_id: 會員編號 type String(字串)
 * }
 * @returns
 */

export const GetProviderListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/users`, { params });
};

export const GetOccupations = () => {
    return axios.get(`${apiConfig}/categories/occupations`);
};

export const GetWeeklyBusinessHours = (id) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/weekly-business-hours`);
};
/**
 * 更新服務商營業時段
 * @param { type Number } id userID
 * @param { type Object(物件) } data
 * @example {
 *  weekdays:
 *   [
 *       {
 *           "day": 1,
 *           "hours": [9, 10, 11]
 *       },
 *       {
 *           "day": 6,
 *           "hours": [15, 16, 18]
 *       }
 *   ]
 * }
 * @returns
 */
export const UpdateWeeklyBusinessHours = (id, data) => {
    return axios.post(`${apiConfig}/backyard/users/${id}/weekly-business-hours`, data);
};
/**
 * 設定是否啟用city ai 智能客服
 * @param { type String(字串) } userId 使用者 id
 * @param { type Boolean(布林值) } enableCityAi 判斷是否啟用 city Ai 智能客服
 */
export const SetCtiyAiApi = (userId, enableCityAi) => {
    return axios.patch(`${apiConfig}/backyard/users/${userId}/settings`, { enableCityAi });
};

export default {
    GetProviderListApi,
    GetOccupations,
    GetWeeklyBusinessHours,
    UpdateWeeklyBusinessHours,
    SetCtiyAiApi,
};
