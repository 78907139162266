import Vue from "vue";
import Router from "vue-router";
// cms 路由入口點
import setCMSRouter from "@/router/utils/cmsEntry";
// 經紀人後台 路由入口點
import setBrokerRouter from "@/router/utils/brokerEntry";

Vue.use(Router);
let router = null;

// 依據env參數不同設置路由
if (process.env.VUE_APP_SYSTEM === "cms") {
    // cms 路由設置
    router = setCMSRouter();
} else {
    // 經紀人後台 路由設置
    router = setBrokerRouter();
}

export default router;
