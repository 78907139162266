// import { setStorage, getStorage, removeStorage } from "../service/localStorage";
// 取得登入者資料 api
// import { LoginUserInfoApi } from "../api/userApi";
// import { Message } from "element-ui";
export default {
    namespaced: true,
    // data
    state: {
        // 儲存會員資料
        memberData: {},
    },
    // computed
    getters: {},
    // methods
    mutations: {
        // 設定會員資料
        setMemberData(state, val) {
            state.memberData = val;
        },
    },
    // mounted ajax的方法都寫在這
    actions: {},
};
