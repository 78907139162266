const permissionRouter = {
    path: "/permission_control",
    name: "permission_control",
    icon: "fas fa-users",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "permission_control",
        text: "權限管理",
        custom_route_name: "permission_routes",
    },
    children: [
        {
            path: "permission_routes",
            name: "permission_routes",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/permission/routes/index.vue"),
            meta: {
                name: "permission_routes",
                parent: "permission_control",
                text: "路由權限列表",
                isMenu: false,
            },
        },
        {
            path: "permission_route_set/:id",
            name: "permission_route_set",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/permission/routes/Form.vue"),
            meta: {
                name: "permission_route_set",
                parent: "permission_control",
                breadcrumb: ["路由權限列表", "路由設定"],
                text: "路由設定",
                isMenu: false,
            },
        },
        {
            path: "permission_roles",
            name: "permission_roles",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/permission/roles/index.vue"),
            meta: {
                name: "permission_roles",
                parent: "permission_control",
                text: "角色列表",
                isMenu: false,
            },
        },
        {
            path: "permission_role_set/:id?",
            name: "permission_role_set",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/permission/roles/Form.vue"),
            meta: {
                name: "permission_role_set",
                parent: "permission_control",
                breadcrumb: ["角色列表", "角色"],
                isMenu: false,
            },
        },
        {
            path: "permission_users",
            name: "permission_users",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/permission/users/index.vue"),
            meta: {
                name: "permission_users",
                parent: "permission_control",
                text: "人員列表",
                isMenu: false,
            },
        },
        {
            path: "permission_user_set/:id?",
            name: "permission_user_set",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/permission/users/Form.vue"),
            meta: {
                name: "permission_user_set",
                parent: "permission_control",
                breadcrumb: ["人員列表", "人員"],
                isMenu: false,
            },
        },
    ],
};

export default permissionRouter;
