import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
/**
 * 經紀人登入
 * @param { type String } phone
 * @param { type String } password
 */
export const LoginBrokerApi = (data) => {
    return axios.post(`${apiConfig}/brokers/login`, data);
};

// 取得登入使用者資料
export const LoginBrokerInfoApi = () => {
    return axios.get(`${apiConfig}/brokers/user-profile`);
};

export const LogoutBrokerApi = () => {
    return axios.post(`${apiConfig}/brokers/logout`);
};

// 重取 token
export const RefreshBrokerTokenApi = () => {
    return axios.post(`${apiConfig}/brokers/refresh`);
};

export default {
    LoginBrokerApi,
    LoginBrokerInfoApi,
    LogoutBrokerApi,
    RefreshBrokerTokenApi,
};
