<template>
    <transition
        enter-class="opacity-0 transition duration-300"
        enter-to-class="opacity-1 transition duration-300"
        leave-active-class="opacity-0 transiton duration-500">
        <div v-if="showDialog">
            <div class="fixed h-screen w-screen top-0 left-0 z-40 flex justify-center items-center" style="background: rgba(0, 0, 0, 0.7)">
                <div v-click-outside="closeDialog" :class="customClass">
                    <div v-if="loading" class="loading">
                        <img src="/img/Infinity-loading.svg" width="90px" height="90px" />
                    </div>
                    <slot> </slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
    name: "Dialog",
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        // 判斷是否顯示 彈窗
        showDialog: {
            type: Boolean,
            default: false,
        },
        // 自定義 class
        customClass: {
            require: false,
            type: [String, Array],
            default: "",
        },
        loading: {
            require: false,
            type: Boolean,
            delete: false,
        },
        clickOutsideClose: {
            require: false,
            type: Boolean,
            default: true,
        },
    },
    methods: {
        closeDialog() {
            if (this.clickOutsideClose) {
                this.$emit("onCloseDialog");
            }
        },
    },
};
</script>

<style scoped>
.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 300;
    background-color: rgba(131, 131, 131, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
