import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * @param { type Number } limit items per page, default is 30
 * @param { type Number } order_id  order identity
 * @param { type String } phone user's phone number
 * @param { type Number } status payment's status, 0 or 1 or -1 or -2
 * @param { type String } type payment type
 * @param { type String } start_date: payment time after this date
 * @param { type String } end_date: payment time before this date
 * @param { type String } created_at_start: creates time after this date
 * @param { type String } created_at_end: created time before this dat
 * @returns 付款紀錄
 */

export const SearchPaymentsAPI = (params) => {
    return axios.get(`${apiConfig}/backyard/search-payments`, { params });
};

/**
 * @param { type Number } limit items per page, default is 30
 * @param { type Number } status remittances's status, 0(服務商或系統提出提領), 1(會計確認無誤), 2(匯出銀行檔案), 3(完成匯款), -1(發生錯誤)
 * @param { type Number } transaction_log_id  transaction log identity
 * @param { type String } phone user's phone number
 * @param { type String } reason reason of remittances
 * @param { type String } complete_date_start 匯款時間
 * @param { type String } complete_date_end 匯款時間
 * @param { type String } created_date_start 申請時間
 * @param { type String } created_date_end 申請時間
 * @param { type String } sort_by 申請時間 created_at (default), export_time or complete_time
 * @returns 會計列表
 */

export const SearchRemittancesAPI = (params) => {
    return axios.get(`${apiConfig}/backyard/search-remittances`, { params });
};

/**
 * @param { type Number } status 0:SUBMITTED, 1:REVIEWED, 2:EXPORTED, 3:COMPLETED, 4:ERROR
 * @param { type Number } assignee [integer]
 * @param { type String } description description
 * @returns 更新對帳 status
 */

export const UpdateRemittanceStatus = (id, params) => {
    return axios.patch(`${apiConfig}/backyard/remittances/${id}`, params);
};

/**
 * @param { type Number } status 0:SUBMITTED, 1:REVIEWED, 2:EXPORTED, 3:COMPLETED, 4:ERROR
 * @param { type Number } ids [array of remittance identity]
 * @returns 複選更新對帳 status
 */

export const UpdateRemittanceStatusArray = (params) => {
    return axios.patch(`${apiConfig}/backyard/remittances`, params);
};

/**
 * @param { type Number } id remittance's id
 * @returns 取得單筆 remittance 資訊
 */

export const GetRemittanceDetail = (id) => {
    return axios.get(`${apiConfig}/backyard/remittances/${id}`);
};

/**
 * @param { type Array } ids remittance's ids
 * @param { type String } date [required | date:Ymd | after_or_equal:today]
 * @param { type String } comment string
 * @returns 匯出 remittance 單
 */

export const ExportRemittanceText = (params) => {
    return axios.post(`${apiConfig}/backyard/export-remittances`, params, { responseType: "arraybuffer" });
};

/**
 * 檢查金流匯出資料是否正確
 * @param { type Array } ids remittance's ids
 * @returns { failed , passed }
 */

export const CheckRemittances = (params) => {
    return axios.post(`${apiConfig}/backyard/check-remittances`, params);
};

export default {
    SearchPaymentsAPI,
    SearchRemittancesAPI,
    UpdateRemittanceStatus,
    UpdateRemittanceStatusArray,
    GetRemittanceDetail,
    ExportRemittanceText,
    CheckRemittances,
};
