import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 取得服務商列表
 * @param { type Object(物件) } params 過濾參數
 * @example {
 * limie: 20 (type Number) 單頁呈現幾筆資料,
 * roles: [0,1] (type Array) 0 = 會員 1 = 已上架服務商 2 = 已下架服務商,
 * sort_by: (type String) 排序依據key,
 * sort_type: (type String) desc or asc 倒序或正序 (desc 倒序 asc 正序)
 * }
 * @returns
 */
export const GetMemberListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/users`, {
        params: { roles: [0], ...params },
    });
};

/**
 * 搜尋服務商
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  name: 暱稱 type String(字串)
 *  birthday: 生日  type String(字串) ex:"05-05"
 *  phone: 手機號碼 type String(字串) ex:886912321321
 *  status: 啟用狀態 type String or Number(字串或數字) 0: 使用中 1 停權 2 永久停權
 *  banana_id: 會員編號 type String(字串)
 * }
 * @returns
 */
export const SearchMemberApi = (params) => {
    return axios.get(`${apiConfig}/backyard/search-profile`, { params });
};

/**
 * 搜尋實名驗證
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 * type: integer
 * level: array of integer
 * user_id: integer
 * administrator_id: integer
 * status: array of integer
 * limit: items per page, default is 30
 * sort_by: string, column name
 * sort_type: string, either asc or desc
 * }
 * @returns
 */
export const SearchAuthenticationsApi = (params) => {
    return axios.get(`${apiConfig}/backyard/authentications`, { params });
};

/**
 * 搜尋實名驗證 By id
 */
export const SearchAuthenticationsIDApi = (id) => {
    return axios.get(`${apiConfig}/backyard/authentications/${id}`);
};

/**
 * 核銷實名驗證 By id
 */
export const ApplyAuthenticationsIDApi = (id, params) => {
    return axios.patch(`${apiConfig}/backyard/authentications/${id}`, params);
};

export default {
    GetMemberListApi,
    SearchMemberApi,
    SearchAuthenticationsApi,
    SearchAuthenticationsIDApi,
    ApplyAuthenticationsIDApi,
};
