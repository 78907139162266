import Vue from "vue";
// cms相關 API
import userApi from "./cms/userApi";
import utilsApi from "./cms/utilsApi";
import firebaseAuthApi from "./cms/firebaseAuthApi";
import providerApi from "./cms/providerApi";
import memberApi from "./cms/memberApi";
import chatApi from "./cms/chatApi";
import accountApi from "./cms/accountApi";
import homeSettingApi from "./cms/homeSettingApi";
import orderApi from "./cms/orderApi";
import customerComplaintApi from "./cms/customerComplaintApi";
import permessionApi from "./cms/permessionApi";
import campaignApi from "./cms/campaignApi";
import brokerApi from "./cms/brokerApi";

// 經紀人相關 API
import brokerLoginApi from "./broker/loginApi";
import brokerHomeApi from "./broker/homeApi";

Vue.prototype.$api = {
    ...userApi,
    ...utilsApi,
    ...firebaseAuthApi,
    ...providerApi,
    ...chatApi,
    ...accountApi,
    ...homeSettingApi,
    ...memberApi,
    ...orderApi,
    ...customerComplaintApi,
    ...permessionApi,
    ...brokerHomeApi,
    ...brokerLoginApi,
    ...campaignApi,
    ...brokerApi,
};
