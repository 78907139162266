import store from "@/store";
import { isEmpty } from "./anyService";
import { getStorage, removeStorage, setStorage } from "./localStorage";
import { Message } from "element-ui";
// 重取經紀公司後台 token 方法
import { RefreshBrokerTokenApi } from "@/api/broker/loginApi";
// 重取 cms token 方法
import { RefreshTokenApi } from "@/api/cms/userApi";
import moment from "moment";

// 重取 broker api token (經紀公司後台 token)
export const refreshBrokerToken = async () => {
    // 取得現在時間
    const nowTime = moment().valueOf();
    // 取得 refresh token 時間
    const refreshTime = getStorage("brokerTokenRefreshTime");
    // 判斷沒有refresh token 時間不執行
    if (isEmpty(refreshTime)) {
        return;
    }
    // 判斷現在時間小於 refresh token 時間不執行
    if (nowTime < refreshTime) {
        return;
    }
    try {
        // 重取 token 方法
        const { data } = await RefreshBrokerTokenApi();
        /**
         * 設定重取 token 時間 以及更新目前 token 因為非同步問題 有可能導致時間還沒更新
         * 又觸發此條件 因此等待設定完後才能往下執行
         */
        const setTokenAndRefreshTime = function () {
            return new Promise((resolve) => {
                setStorage("token", "Bearer " + data.access_token);
                setStorage("brokerTokenRefreshTime", moment().add(7, "days").valueOf());
                resolve();
            });
        };
        await setTokenAndRefreshTime();
        return;
    } catch (err) {
        // 移除 refresh broker api token 時間
        removeStorage("brokerTokenRefreshTime");
        // 移除 refresh cms api token 時間
        removeStorage("tokenRefreshTime");
        // 移除  refresh firebase token 時間
        removeStorage("firebaseTokenRefreshTime");
        Message({
            type: "error",
            message: "重取token失敗",
        });
        return;
    }
};
// 重取 cms api token
export const refreshApiToken = async () => {
    // 取得現在時間
    const nowTime = moment().valueOf();
    // 取得 refresh token 時間
    const refreshTime = getStorage("tokenRefreshTime");
    // 判斷沒有refresh token 時間不執行
    if (isEmpty(refreshTime)) {
        return;
    }
    // 判斷現在時間小於 refresh token 時間不執行
    if (nowTime < refreshTime) {
        return;
    }
    try {
        // 重取 token 方法
        const { data } = await RefreshTokenApi();
        /**
         * 設定重取 token 時間 以及更新目前 token 因為非同步問題 有可能導致時間還沒更新
         * 又觸發此條件 因此等待設定完後才能往下執行
         */
        const setTokenAndRefreshTime = function () {
            return new Promise((resolve) => {
                setStorage("token", "Bearer " + data.access_token);
                setStorage("tokenRefreshTime", moment().add(7, "days").valueOf());
                resolve();
            });
        };
        await setTokenAndRefreshTime();
        return;
    } catch (err) {
        // 移除 refresh broker api token 時間
        removeStorage("brokerTokenRefreshTime");
        // 移除 refresh cms api token 時間
        removeStorage("tokenRefreshTime");
        // 移除  refresh firebase token 時間
        removeStorage("firebaseTokenRefreshTime");
        Message({
            type: "error",
            message: "重取token失敗",
        });
        return;
    }
};

// 重取 firebase token
export const refreshFirebaseApiToken = async (userId) => {
    // 取得現在時間
    const nowTime = moment().valueOf();
    // 取得 refresh token 時間
    const refreshTime = getStorage("firebaseTokenRefreshTime");
    // 判斷沒有refresh token 時間不執行
    if (isEmpty(refreshTime)) {
        return;
    }
    // 判斷現在時間小於 refresh token 時間不執行
    if (nowTime < refreshTime) {
        return;
    }
    try {
        // 重取 firebase token 並重新登入 firebase
        await store.dispatch("chatStore/getFirebaseToken", userId);
        // 設定重取 token 時間 因為非同步問題 有可能導致時間還沒更新 又觸發此條件 因此等待設定完後才能往下執行
        const setRefreshTime = function () {
            return new Promise((resolve) => {
                setStorage("firebaseTokenRefreshTime", moment().add(30, "minutes").valueOf());
                resolve();
            });
        };
        await setRefreshTime();
        return;
    } catch (err) {
        // 移除 refresh broker api token 時間
        removeStorage("brokerTokenRefreshTime");
        // 移除 refresh cms api token 時間
        removeStorage("tokenRefreshTime");
        // 移除  refresh firebase token 時間
        removeStorage("firebaseTokenRefreshTime");
        Message({
            type: "error",
            message: "重取firebasetoken失敗",
        });
        return;
    }
};
