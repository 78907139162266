import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 訂單紀錄
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  sort_by: either created_at or started_at or gross_price
 *  sort_type: asc or desc
 *  role: required, 0 means consumer and 1 means provider
 *  order_id: order identity
 *  started_at_start: the min date of started_at
 *  started_at_end: the max date of started_at
 *  category_id: dating category identity
 *  status: dating status
 *  rated_only: 1 means listing rating score > 0 only
 * }
 * @returns
 */

const SearchDatings = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/datings`, { params });
};

const GetDating = (id) => {
    return axios.get(`${apiConfig}/backyard/datings/${id}`);
};

const SearchAllDatings = (params) => {
    return axios.get(`${apiConfig}/backyard/datings`, { params });
};

/**
 * 更新約會紀錄
 * @example {
 *  status: [required | integer | in:-1, -3, -4, 6]
 *  amount: integer | min:1 返還金額
 *  feedback_id:  客訴單編號
 *  note: 變更原因
 * }
 * @returns
 */

const UpdateDating = (id, params) => {
    return axios.patch(`${apiConfig}/backyard/datings/${id}`, params);
};

/**
 * 金流紀錄
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  transaction_id: transaction identity
 *  created_at_start:  the min date of created_at
 *  created_at_end: the max date of created_at
 *  type: transaction type
 * }
 * @returns
 */

const SearchTransactions = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/transactions`, {
        params,
    });
};

/**
 * 評價紀錄
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  sort_by: either created_at or started_at or gross_price
 *  sort_type: asc or desc
 *  role: required, 0 means consumer and 1 means provider
 *  order_id: order identity
 *  started_at_start: the min date of started_at
 *  started_at_end: the max date of started_at
 *  category_id: dating category identity
 *  status: dating status
 *  rated_only: 1 means listing rating score > 0 only
 * }
 * @returns
 */

const SearchDatingByUser = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/datings`, { params });
};

/**
 * 評價分數統計
 * @param { type String } id 搜尋條件
 * @returns
 */

const GetSummaryRatting = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/rating-summary`, {
        params,
    });
};

/**
 * 新增扣除餘額
 * @param { type id } id userID
 * @param { type String } feedback_id 客訴單號
 * @param { type Number } amount 金額
 * @returns
 */

const UpdateUserBalance = (id, params) => {
    return axios.post(`${apiConfig}/backyard/users/${id}/balance`, params);
};

/**
 * 取得訂單狀態更新LOGS
 * @param { type id } id 訂單編號
 */

const GetOrderLogs = (id) => {
    return axios.get(`${apiConfig}/backyard/datings/${id}/status-logs`);
};

/**
 * 取得使用者快閃折抵金紀錄
 * @param { type number } limit items per page
 * @param { type string } sort_by orting by which column
 * @param { type string } sort_type either asc or desc
 */

const GetUserVoucherList = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/voucher-logs`, { params });
};

/**
 * 取得使用者快閃折抵金紀錄
 * @param { type number } limit items per page
 * @param { type string } sort_by orting by which column
 * @param { type string } sort_type either asc or desc
 */

const CreateUserVoucher = (id, data) => {
    return axios.post(`${apiConfig}/backyard/users/${id}/vouchers`, data);
};

/**
 * 取得使用者快閃折抵金紀錄
 * @param { type number } limit items per page
 * @param { type string } sort_by orting by which column
 * @param { type string } sort_type either asc or desc
 */

const GetUserVoucherTickets = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/vouchers`, { params });
};

/**
 * 更新折抵金狀態
 * @param { type number } feedback_id feedback_id
 * @param { type number } status  in:0 (正常),-1 (過期), -2 (收回)]
 * @param { type string } code 折抵金編號
 */

const UpdateSingleVoucher = (code, data) => {
    return axios.patch(`${apiConfig}/backyard/vouchers/${code}`, data);
};

export default {
    SearchAllDatings,
    SearchDatings,
    GetDating,
    UpdateDating,
    SearchTransactions,
    SearchDatingByUser,
    GetSummaryRatting,
    UpdateUserBalance,
    GetOrderLogs,
    GetUserVoucherList,
    CreateUserVoucher,
    GetUserVoucherTickets,
    UpdateSingleVoucher,
};
