import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
/**
 * 檢查金流匯出資料是否正確
 * @param { type Array } ids remittance's ids
 * @returns { failed , passed }
 */
export const GetNewsApi = () => {
    return axios.get(`${apiConfig}/expo/news`);
};

/**
 * 取得經紀人服務商列表
 */
const SearchMyProvider = (data) => {
    return axios.get(`${apiConfig}/brokers/my/providers`, { params: data });
};

/**
 * 取得保底政策方案
 */
const GetPrviderPlan = () => {
    return axios.get(`${apiConfig}/backyard/plans`);
};

/**
 * 經紀人首頁統計資料
 */
const GetBrokerPerformanceStatistics = () => {
    return axios.get(`${apiConfig}/brokers/my/providers/performance`);
};

export default {
    GetNewsApi,
    GetPrviderPlan,
    SearchMyProvider,
    GetBrokerPerformanceStatistics,
};
