import Vue from "vue";
import Vuex from "vuex";

import userStore from "./modules/userStore";
import permissionStore from "./modules/permissionStore";
import breadcrumbStore from "./modules/breadcrumbStore";
import i18nStore from "./modules/i18nStore";
import providerStore from "./modules/providerStore";
import memberStore from "./modules/memberStore";
import chatStore from "./modules/chatStore";
import apiStore from "./modules/apiStore";

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    modules: {
        userStore,
        permissionStore,
        breadcrumbStore,
        i18nStore,
        providerStore,
        memberStore,
        chatStore,
        apiStore,
    },
    state: {
        // 判斷是否呈現 黑色系樣式
        showDark: false,
        // 判斷是否呈現手機版選單
        showMenu: false,
    },
    mutations: {
        // 設定黑色系樣式事件
        setShowDark(state, val) {
            state.showDark = val;
        },
        // 設定開關手機版選單事件
        setShowMenu(state, val) {
            state.showMenu = val;
        },
    },
    actions: {},
});
